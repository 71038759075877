import React from 'react'
import styles from './PackageVehicleImages.module.css'
import { PTag } from '@porsche-design-system/components-react'
import { Vehicle } from '../../../../Models/Vehicle'

const CAR_DISPLAY_LIMIT = 4
const PackageVehicleImages = (props: { vehicles?: Vehicle[] }) => {
  const vehicles = props.vehicles ?? []
  const vehicleImages = vehicles.map((item, i) => (
    <img
      key={i}
      className={styles.image}
      src={item.preview || '/Car-Preview.png'}
      alt="car preview"
    />
  ))

  const vehicleImageDisplay =
    vehicles.length > CAR_DISPLAY_LIMIT ? (
      <>
        {vehicleImages.slice(0, CAR_DISPLAY_LIMIT)}
        <PTag
          className={styles.remainingCarsTag}
          aria-label="remaining-vehicles"
        >
          +{vehicles.length - CAR_DISPLAY_LIMIT}
        </PTag>
      </>
    ) : (
      vehicleImages
    )

  return <div className={styles.container}>{vehicleImageDisplay}</div>
}

export default PackageVehicleImages
