import {
  PButtonPure,
  PCheckboxWrapper,
  PIcon,
  PPopover,
  PTableCell,
  PTableRow,
  PTag,
  PText,
} from '@porsche-design-system/components-react'
import styles from '../IncomingTab/IncomingVehiclesContent.module.css'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Vehicle } from '../../../Models/Vehicle'
import { useTranslation } from 'react-i18next'
import {
  kilometerFormatting,
  priceFormatting,
} from '../../../helpers/helperFunctions'

export function getDate(dateType?: string) {
  return dateType === undefined || dateType === null
    ? '—'
    : new Date(Date.parse('' + dateType)).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
}

function getReturnedDays(item: Vehicle) {
  return item.date_of_return === undefined || item.date_of_return === null
    ? NaN
    : Math.floor(
        (Date.now() - Date.parse(item.date_of_return)) / (1000 * 60 * 60 * 24),
      )
}

const ReturnedVehicleRow = (props: {
  vehicle: Vehicle
  selected: boolean
  selectVehicle: (vehicle: Vehicle) => void
  deselectVehicle: (vin: string) => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToDetails = () => {
    navigate(`/details/${props.vehicle.vin}`)
  }

  return (
    <PTableRow role="row">
      <PTableCell>
        <PButtonPure
          icon="none"
          onClick={navigateToDetails}
          aria-label="open vehicle details"
        >
          <img
            className={styles.image}
            src={props.vehicle.preview || '/Car-Preview.png'}
            alt="car preview"
          />
        </PButtonPure>
      </PTableCell>
      <PTableCell>
        {props.vehicle.derivative ? (
          <PButtonPure weight="bold" icon="none" onClick={navigateToDetails}>
            <u>{props.vehicle.derivative}</u>
            {props.vehicle.extra_tyres ? (
              <PIcon name="snowflake" size="small"></PIcon>
            ) : (
              <></>
            )}
          </PButtonPure>
        ) : (
          <PText>{'–'}</PText>
        )}
        <PText>{props.vehicle.vin}</PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {props.vehicle.exterior_color?.name}
          <PPopover
            className={styles.popover}
            direction="top"
            description={props.vehicle.exterior_color?.description}
          />
        </PText>
        <PText>
          {props.vehicle.interior_color?.name}
          <PPopover
            className={styles.popover}
            direction="top"
            description={props.vehicle.interior_color?.description}
          />
        </PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {getDate(props.vehicle.date_of_first_registration) || '—'}
        </PText>
        <PText>
          {(props.vehicle.mileage &&
            kilometerFormatting(props.vehicle.mileage)) ||
            '—'}
        </PText>
      </PTableCell>
      <PTableCell>
        {!props.vehicle.blocked_for_sales && (
          <PText>
            {(props.vehicle.bundle_auction &&
              props.vehicle.initial_price &&
              `${priceFormatting(props.vehicle.initial_price)}`) ||
              (props.vehicle.auction?.initial_price &&
                `${priceFormatting(
                  Number(props.vehicle.auction.initial_price),
                )}`) ||
              '—'}
          </PText>
        )}
        <PText>{priceFormatting(Number(props.vehicle.gross_price))}</PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {props.vehicle.blocked_for_sales ? (
            <PTag color="notification-error-soft" icon="racing-flag">
              {t('sellerView.blockedForSalesStatus')}
            </PTag>
          ) : props.vehicle.has_a_complete_auction ? (
            <PTag color="notification-warning-soft">
              {t('sellerView.failedAuctionStatus')}
            </PTag>
          ) : (
            <PTag color="background-surface">
              {t('sellerView.returnedStatus')}
            </PTag>
          )}
        </PText>
        <div className={styles.returnedDateAndDays}>
          <PText>{getDate(props.vehicle.date_of_return) || '—'}</PText>
          {getDate(props.vehicle.date_of_return) !== '—' && (
            <PText className={styles.returnedDays}>
              (
              {t('sellerView.returnDateDays', {
                count: getReturnedDays(props.vehicle),
              })}
              )
            </PText>
          )}
        </div>
      </PTableCell>
      <PTableCell>
        {props.vehicle.showroom ? (
          <PIcon name="home" size="small"></PIcon>
        ) : (
          <></>
        )}
        {props.vehicle.reserved ? (
          <PIcon name="user" size="small"></PIcon>
        ) : (
          <></>
        )}
        {props.vehicle.crash ? (
          <PIcon name="wrenches" size="small"></PIcon>
        ) : (
          <></>
        )}
      </PTableCell>
      {!props.vehicle.blocked_for_sales && (
        <PTableCell>
          <div className={styles.returnedDateAndDays}>
            <PTag
              color={
                props.vehicle.bundle_auction === null
                  ? 'primary'
                  : props.vehicle.bundle_auction
                    ? 'notification-info-soft'
                    : 'background-surface'
              }
            >
              {props.vehicle.bundle_auction === null
                ? 'N'
                : props.vehicle.bundle_auction
                  ? 'P'
                  : 'S'}
            </PTag>

            {props.vehicle.bundle_auction && (
              <PCheckboxWrapper className={styles.bundleCheckbox}>
                <input
                  type="checkbox"
                  checked={props.selected}
                  onChange={() =>
                    props.selected
                      ? props.deselectVehicle(props.vehicle.vin)
                      : props.selectVehicle(props.vehicle)
                  }
                />
              </PCheckboxWrapper>
            )}
          </div>
        </PTableCell>
      )}
    </PTableRow>
  )
}

export default ReturnedVehicleRow
