import { useEffect, useState } from 'react'
import { Amplify, Auth } from 'aws-amplify'
import loadEnvironment from '../loadEnvironment'
import i18n from '../setupTranslations'

const environment = loadEnvironment()

Amplify.configure({
  Auth: {
    region: environment.AWS_REGION,
    userPoolId: environment.COGNITO_USER_POOL_ID,
    userPoolWebClientId: environment.COGNITO_CLIENT_ID,
    oauth: {
      domain: environment.COGNITO_DOMAIN,
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: `${window.location.origin}/`,
      redirectSignOut: `${window.location.origin}/`,
      responseType: 'code',
    },
  },
})

export default function usePPNAuthentication() {
  const [systemRoles, setSystemRoles] = useState({
    isSeller: false,
    isAdmin: false,
    isDealer: false,
  })
  const [userId, setUserId] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    const authenticate = async () => {
      try {
        const currentSession = await Auth.currentSession()
        const payload = currentSession.getIdToken().payload
        const username = payload['cognito:username'] ?? payload.username
        const { given_name, family_name, identities, locale } = payload

        i18n.changeLanguage(locale)

        const usernameTrimmed = username.replace(/^ppn_|^ppn-lite_/g, '')
        const isSeller =
          payload['cognito:groups']?.includes(environment.SELLER_GROUP_NAME) ??
          false
        const isAdmin =
          payload['cognito:groups']?.includes(environment.ADMIN_GROUP_NAME) ??
          false

        setSystemRoles({
          isSeller: isSeller,
          isAdmin: isSeller && isAdmin,
          isDealer: !isSeller && !!usernameTrimmed,
        })

        if (identities?.length > 0) {
          setUserId(identities[0].userId)
        }

        setDisplayName(`${given_name} ${family_name}`)
        setIsAuthorized(true)
      } catch {
        try {
          await Auth.federatedSignIn({
            customProvider: environment.CUSTOM_PROVIDER,
          })
        } catch {
          setUserId('')
          setDisplayName('')
          setIsAuthorized(false)
          setSystemRoles({
            isSeller: false,
            isAdmin: false,
            isDealer: false,
          })
        }
      }
    }

    authenticate()
  }, [])

  return { isAuthorized, userId, displayName, systemRoles }
}
