import React, { useCallback, useContext, useEffect, useState } from 'react'
import styles from '../../IncomingTab/IncomingVehiclesContent.module.css'
import {
  PPopover,
  PSpinner,
  PTable,
  PTableBody,
  PTableHead,
  PTableHeadCell,
  PTableHeadRow,
  PText,
} from '@porsche-design-system/components-react'

import LiveSaleVehicleRow from './LiveSaleVehicleRow'
import { UserInfo } from '../../../../App'
import {
  AVAILABLE_DERIVATIVE_FILTERS,
  AVAILABLE_MODEL_FILTERS,
  NO_DERIVATIVE_SELECTED,
} from '../../../Filters/FilterConstants'
import useInfiniteVehicles from '../../../../Hooks/useInfiniteVehicles'
import { useBids } from '../../../../Hooks/useBids'
import FilterRow from '../../../Filters/FilterRow'
import { useTranslation } from 'react-i18next'
import { AuctionStatusMapping } from '../LiveSalePackageAuctions/LiveSalePackagesContent'
import { AuctionCountdown } from '../../../AuctionCountdown'
import {
  readFromSessionStorage,
  useSessionStorage,
} from '../../../../Hooks/filterHooks'
import { FavouriteTableHeadCell } from '../../../FavouriteAuction'
import loadEnvironment from '../../../../loadEnvironment'
import { HotBiddingHead } from '../HotBiddingCell'

export type AuctionStatus = {
  currentHighestBid: number
  maxBidId: string
  totalBids: number
}

export type WebSocketNotification = {
  auctionId: string
  currentHighestBid: number
  maxBidId: string
  totalBids: number
}

export default function LiveSaleVehiclesContent(props: {
  pageSize: number
  auctionStatusMapping: AuctionStatusMapping
  withCountdown?: boolean
}) {
  const { t } = useTranslation()
  const [pageIndex, setPageIndex] = useState(1)
  const auctionStatusMapping = props.auctionStatusMapping
  const [primaryStatusFilter] = useState([])
  const { isSeller, isDealer } = useContext(UserInfo)
  const environment = loadEnvironment()
  const [favFilterCheckbox, setFavFilterCheckbox] = useState<boolean>(
    readFromSessionStorage('favCheckValueLiveSale', 'false') === 'true',
  )
  useSessionStorage(favFilterCheckbox.toString(), 'favCheckValueLiveSale')

  const [modelFilter, setModelFilter] = useState(
    readFromSessionStorage(
      'modelFilter',
      AVAILABLE_MODEL_FILTERS[0],
      (x: string) => AVAILABLE_MODEL_FILTERS.includes(x),
    ),
  )
  useSessionStorage(modelFilter, 'modelFilter')
  const [derivativeFilter, setDerivativeFilter] = useState(
    readFromSessionStorage(
      'derivativeFilter',
      NO_DERIVATIVE_SELECTED,
      (x: string) =>
        x.split(',').find((z) => AVAILABLE_DERIVATIVE_FILTERS.includes(z)) !==
        undefined,
    ).split(','),
  )
  useSessionStorage(derivativeFilter.toString(), 'derivativeFilter')

  const { vehicles, totalVehicles, nextPage } = useInfiniteVehicles(
    modelFilter,
    derivativeFilter,
    primaryStatusFilter,
    '',
    false,
    false,
    true,
    pageIndex,
    props.pageSize,
    undefined,
    undefined,
    undefined,
    isDealer ? favFilterCheckbox : undefined,
  )

  const [refreshKey, setRefreshKey] = useState(0)
  const [bids, lastRefresh] = useBids(refreshKey)

  const handleScroll = useCallback(() => {
    const e = document.documentElement
    if (
      e.scrollTop + e.clientHeight >= e.scrollHeight - 300 &&
      pageIndex !== nextPage &&
      totalVehicles - props.pageSize * pageIndex > 0
    ) {
      setPageIndex(nextPage)
    }
  }, [props.pageSize, pageIndex, nextPage, totalVehicles, setPageIndex])

  useEffect(() => {
    const delay = window.setTimeout(handleScroll, 100)
    return () => window.clearTimeout(delay)
  }, [vehicles, handleScroll])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  //TODO: add filter in dependency
  useEffect(() => setPageIndex(1), [modelFilter, derivativeFilter])

  return (
    <>
      <PText className={styles.title} size="medium" weight="semi-bold">
        {t('sharedView.singleAuction')}
      </PText>
      {props.withCountdown ? (
        <div className={styles.countDownRow}>
          <PText className={styles.listSize} size="small" weight="semi-bold">
            {t('sharedView.vehicleCount', { count: totalVehicles })}
          </PText>
          <AuctionCountdown className={styles.countDown} />
        </div>
      ) : (
        <PText className={styles.listSize} size="small" weight="semi-bold">
          {t('sharedView.vehicleCount', { count: totalVehicles })}
        </PText>
      )}
      <div className={styles.filterCountDownRow}>
        <FilterRow
          onChangeModel={setModelFilter}
          modelFilter={modelFilter}
          onChangeDerivative={setDerivativeFilter}
          derivativeFilter={derivativeFilter}
          onChangeArbitraryDate={null}
          arbitraryDateFilter={null}
          onFavCheckboxChange={setFavFilterCheckbox}
          favFilterDefaultValue={favFilterCheckbox}
        />
      </div>

      {totalVehicles !== 0 && (
        <>
          <PTable
            caption="Some caption"
            aria-label="live-single-auctions"
            className={styles.contentTable}
          >
            <PTableHead>
              <PTableHeadRow>
                <PTableHeadCell role="columnheader">
                  <PText weight="semi-bold">
                    {t('sharedView.vehiclePictures')}
                  </PText>
                </PTableHeadCell>
                <PTableHeadCell role="columnheader">
                  <PText weight="semi-bold">
                    {t('sharedView.vehicleModel')}
                  </PText>
                  <PText weight="semi-bold">
                    {t('sharedView.vehicleIdentificator')}
                  </PText>
                </PTableHeadCell>
                <PTableHeadCell role="columnheader">
                  <PText weight="semi-bold">
                    {t('sharedView.vehicleExteriorColor')}
                  </PText>
                  <PText weight="semi-bold">
                    {t('sharedView.vehicleInteriorColor')}
                  </PText>
                </PTableHeadCell>
                <PTableHeadCell role="columnheader">
                  <PText weight="semi-bold">
                    {t('sharedView.firstRegistration')}
                  </PText>
                  <div className={styles.inlinePopoverContainer}>
                    <PText weight="semi-bold">{t('sharedView.mileage')}</PText>
                    <PPopover
                      className={styles.popover}
                      aria={{ 'aria-label': 'Additional information' }}
                    >
                      {t('sharedView.vehicleMileageInfo')}
                    </PPopover>
                  </div>
                </PTableHeadCell>
                <PTableHeadCell role="columnheader">
                  {isSeller && (
                    <div className={styles.inlinePopoverContainer}>
                      <PText weight="semi-bold">
                        {t('sharedView.vehicleInitialPrice')}
                      </PText>
                      <PPopover
                        className={styles.popover}
                        aria={{
                          'aria-label': 'Initial price additional information',
                        }}
                      >
                        {t('sharedView.vehicleNettoPrice')}
                      </PPopover>
                    </div>
                  )}
                  {isDealer && (
                    <>
                      <div className={styles.inlinePopoverContainer}>
                        <PText weight="semi-bold">
                          {t('dealerView.currentHighestBid')}
                        </PText>
                        <PPopover
                          className={styles.popover}
                          aria={{
                            'aria-label':
                              'Current highest bid additional information',
                          }}
                        >
                          {t('sharedView.vehicleNettoPrice')}
                        </PPopover>
                      </div>
                      <div className={styles.inlinePopoverContainer}>
                        <PText weight="semi-bold">
                          {t('dealerView.myMaximumBid')}
                        </PText>
                        <PPopover
                          className={styles.popover}
                          aria={{
                            'aria-label':
                              'My maximum bid additional information',
                          }}
                        >
                          {t('sharedView.vehicleNettoPrice')}
                        </PPopover>
                      </div>
                    </>
                  )}
                </PTableHeadCell>
                <PTableHeadCell role="columnheader">
                  <PText weight="semi-bold">{t('sharedView.bidsAmount')}</PText>
                </PTableHeadCell>
                <PTableHeadCell role="columnheader">
                  {isDealer && (
                    <PText weight="semi-bold">{t('dealerView.myStatus')}</PText>
                  )}
                  {isSeller && (
                    <div className={styles.inlinePopoverContainer}>
                      <PText weight="semi-bold">
                        Live {t('sellerView.vehiclePriceColumn')}
                      </PText>
                      <PPopover
                        className={styles.popover}
                        aria={{
                          'aria-label': 'Live price additional information',
                        }}
                      >
                        {t('sharedView.vehicleNettoPrice')}
                      </PPopover>
                    </div>
                  )}
                </PTableHeadCell>
                {isDealer && <FavouriteTableHeadCell />}
                {environment.FEATURE_TOGGLES.ENABLE_HOT_BIDDING && isDealer && (
                  <HotBiddingHead />
                )}
              </PTableHeadRow>
            </PTableHead>
            <PTableBody>
              {vehicles?.map(
                (item, i) =>
                  item.auction && (
                    <LiveSaleVehicleRow
                      key={i}
                      vehicle={item}
                      userMaxBid={
                        bids
                          .filter((bid) => bid.auction_id === item.auction?._id)
                          .sort(
                            (bid_a, bid_b) =>
                              Number(bid_a.bid_amount) -
                              Number(bid_b.bid_amount),
                          )
                          .reverse()[0]
                      }
                      initialPrice={Number(item.auction?.initial_price)}
                      currentHighestBid={
                        auctionStatusMapping[item.auction?._id]
                          ? auctionStatusMapping[item.auction._id]
                              .currentHighestBid
                          : item.auction?.current_bid
                            ? Number(item.auction?.current_bid)
                            : Number(0)
                      }
                      maxBidId={
                        auctionStatusMapping[item.auction?._id]
                          ? auctionStatusMapping[item.auction._id].maxBidId
                          : item.auction?.max_bid_id
                      }
                      totalBids={
                        auctionStatusMapping[item.auction._id]
                          ? auctionStatusMapping[item.auction._id].totalBids
                          : Number(item.auction?.total_bids) || 0
                      }
                      bidListUpdaterSerial={lastRefresh}
                      resetFn={() => {
                        setRefreshKey((prevKey) => prevKey + 1)
                        return refreshKey + 1
                      }}
                    />
                  ),
              )}
            </PTableBody>
          </PTable>
          {pageIndex !== nextPage &&
            totalVehicles - props.pageSize * pageIndex <= 0 && (
              <PText>
                {t('exceptions.reachedEndOfList')}
                <br />
                <br />
              </PText>
            )}
        </>
      )}
      {(!nextPage || pageIndex === nextPage) && (
        <PSpinner
          size="medium"
          aria={{ 'aria-label': 'Loading page content' }}
        />
      )}
      <div className={styles.pagination} />
    </>
  )
}
