import React from 'react'
import { PText } from '@porsche-design-system/components-react'
import { useTranslation } from 'react-i18next'
import tabsBarStyle from '../../TabsBar/TabsBar.module.css'
import BidReport from './BidReport/BidReport'

export default function AnalyticsTab() {
  const { t } = useTranslation()

  return (
    <div className={tabsBarStyle.whiteBackground}>
      <PText className={tabsBarStyle.title} size="large" weight="semi-bold">
        {t('adminView.analyticsTabTitle')}
      </PText>
      <BidReport />
    </div>
  )
}
