interface CountrySpecificationDetails {
  countryName: string
  isRightHandDrive: boolean
}

interface CountryRecord {
  [countryNumber: string]: CountrySpecificationDetails
}

export const CountryRecords: CountryRecord = {
  C00: { countryName: 'Germany', isRightHandDrive: false },
  C02: { countryName: 'USA', isRightHandDrive: false },
  C05: { countryName: 'France', isRightHandDrive: false },
  C07: { countryName: 'Italy', isRightHandDrive: false },
  C08: { countryName: 'Japan', isRightHandDrive: false },
  C09: { countryName: 'Sweden', isRightHandDrive: false },
  C0A: { countryName: 'Puerto Rico', isRightHandDrive: false },
  C0B: { countryName: 'Algeria', isRightHandDrive: false },
  C0C: { countryName: 'Andorra', isRightHandDrive: false },
  C0D: { countryName: 'French Guayana', isRightHandDrive: false },
  C0E: { countryName: 'French Polynesia', isRightHandDrive: false },
  C0F: { countryName: 'Guadeloupe', isRightHandDrive: false },
  C0G: { countryName: 'Morocco', isRightHandDrive: false },
  C0H: { countryName: 'Martinique', isRightHandDrive: false },
  C0J: { countryName: 'Monaco', isRightHandDrive: false },
  C0K: { countryName: 'New Caledonia', isRightHandDrive: false },
  C0L: { countryName: 'Réunion', isRightHandDrive: false },
  C0M: { countryName: 'Tunisia', isRightHandDrive: false },
  C0N: { countryName: 'Iceland', isRightHandDrive: false },
  C10: { countryName: 'Switzerland', isRightHandDrive: false },
  C11: { countryName: 'Austria', isRightHandDrive: false },
  C12: { countryName: 'Denmark', isRightHandDrive: false },
  C13: { countryName: 'Finland', isRightHandDrive: false },
  C14: { countryName: 'Vietnam', isRightHandDrive: false },
  C19: { countryName: 'Luxembourg', isRightHandDrive: false },
  C1A: { countryName: 'Liechtenstein', isRightHandDrive: false },
  C1B: { countryName: 'Bosnia & Herzegovina', isRightHandDrive: false },
  C1C: { countryName: 'Croatia', isRightHandDrive: false },
  C1D: { countryName: 'Macedonia', isRightHandDrive: false },
  C1E: { countryName: 'Montenegro', isRightHandDrive: false },
  C1F: { countryName: 'Romania', isRightHandDrive: false },
  C1G: { countryName: 'Serbia', isRightHandDrive: false },
  C1H: { countryName: 'Slovakia', isRightHandDrive: false },
  C1J: { countryName: 'Slovenia', isRightHandDrive: false },
  C1K: { countryName: 'Czech Republic', isRightHandDrive: false },
  C1L: { countryName: 'Hungary', isRightHandDrive: false },
  C1M: { countryName: 'Latvia', isRightHandDrive: false },
  C1N: { countryName: 'Estonia', isRightHandDrive: false },
  C1P: { countryName: 'Lithuania', isRightHandDrive: false },
  C1Q: { countryName: 'Cambodia', isRightHandDrive: false },
  C1R: { countryName: 'Laos', isRightHandDrive: false },
  C1S: { countryName: 'Macao', isRightHandDrive: false },
  C1T: { countryName: 'Mongolia', isRightHandDrive: false },
  C1U: { countryName: 'Philippines', isRightHandDrive: false },
  C20: { countryName: 'Netherlands', isRightHandDrive: false },
  C21: { countryName: 'Norway', isRightHandDrive: false },
  C22: { countryName: 'Belgium', isRightHandDrive: false },
  C27: { countryName: 'Spain', isRightHandDrive: false },
  C28: { countryName: 'Greece', isRightHandDrive: false },
  C29: { countryName: 'Turkey', isRightHandDrive: false },
  C2C: { countryName: 'Portugal', isRightHandDrive: false },
  C30: { countryName: 'Abu Dhabi', isRightHandDrive: false },
  C31: { countryName: 'Saudi Arabia', isRightHandDrive: false },
  C32: { countryName: 'Dubai', isRightHandDrive: false },
  C33: { countryName: 'China', isRightHandDrive: false },
  C34: { countryName: 'Mexico', isRightHandDrive: false },
  C35: { countryName: 'Chile', isRightHandDrive: false },
  C36: { countryName: 'Canada', isRightHandDrive: false },
  C37: { countryName: 'Brazil', isRightHandDrive: false },
  C38: { countryName: 'Russia', isRightHandDrive: false },
  C39: { countryName: 'South Korea', isRightHandDrive: false },
  C3A: { countryName: 'Bahrain', isRightHandDrive: false },
  C3B: { countryName: 'Jemen', isRightHandDrive: false },
  C3C: { countryName: 'Qatar', isRightHandDrive: false },
  C3D: { countryName: 'Kuwait', isRightHandDrive: false },
  C3E: { countryName: 'Oman', isRightHandDrive: false },
  C3F: { countryName: 'Argentina', isRightHandDrive: false },
  C3G: { countryName: 'Bolivia', isRightHandDrive: false },
  C3H: { countryName: 'Costa Rica', isRightHandDrive: false },
  C3J: { countryName: 'Curacao', isRightHandDrive: false },
  C3K: { countryName: 'Dominican Republic', isRightHandDrive: false },
  C3L: { countryName: 'Ecuador', isRightHandDrive: false },
  C3M: { countryName: 'El Salvador', isRightHandDrive: false },
  C3N: { countryName: 'Guatemala', isRightHandDrive: false },
  C3P: { countryName: 'Haiti', isRightHandDrive: false },
  C3Q: { countryName: 'Honduras', isRightHandDrive: false },
  C3R: { countryName: 'Colombia', isRightHandDrive: false },
  C3S: { countryName: 'Nicaragua', isRightHandDrive: false },
  C3T: { countryName: 'Panama', isRightHandDrive: false },
  C3U: { countryName: 'Paraguay', isRightHandDrive: false },
  C3V: { countryName: 'Peru', isRightHandDrive: false },
  C3W: { countryName: 'St. Maarten', isRightHandDrive: false },
  C3X: { countryName: 'St. Martin', isRightHandDrive: false },
  C3Y: { countryName: 'Uruguay', isRightHandDrive: false },
  C3Z: { countryName: 'Venezuela', isRightHandDrive: false },
  C42: { countryName: 'Taiwan', isRightHandDrive: false },
  C47: { countryName: 'Lebanon', isRightHandDrive: false },
  C4C: { countryName: 'Egypt', isRightHandDrive: false },
  C4E: { countryName: 'Ghana', isRightHandDrive: false },
  C4H: { countryName: 'Jordan', isRightHandDrive: false },
  C4J: { countryName: 'Nigeria', isRightHandDrive: false },
  C4K: { countryName: 'Syria', isRightHandDrive: false },
  C99: { countryName: 'Poland', isRightHandDrive: false },
  C9F: { countryName: 'Armenia', isRightHandDrive: false },
  C9G: { countryName: 'Azerbaijan', isRightHandDrive: false },
  C9H: { countryName: 'Bulgaria', isRightHandDrive: false },
  C9J: { countryName: 'Georgia', isRightHandDrive: false },
  C9K: { countryName: 'Israel', isRightHandDrive: false },
  C9L: { countryName: 'Kazakhstan', isRightHandDrive: false },
  C9M: { countryName: 'Moldova', isRightHandDrive: false },
  C9N: { countryName: 'Ukraine', isRightHandDrive: false },
  C9P: { countryName: 'Belarus', isRightHandDrive: false },
  C9Q: { countryName: 'Uzbekistan', isRightHandDrive: false },
  C15: { countryName: 'Malaysia', isRightHandDrive: true },
  C16: { countryName: 'Great Britain', isRightHandDrive: true },
  C17: { countryName: 'Hongkong', isRightHandDrive: true },
  C18: { countryName: 'Japan', isRightHandDrive: true },
  C1V: { countryName: 'Brunei', isRightHandDrive: true },
  C1W: { countryName: 'Indonesia', isRightHandDrive: true },
  C1X: { countryName: 'Sri Lanka', isRightHandDrive: true },
  C1Y: { countryName: 'Thailand', isRightHandDrive: true },
  C1Z: { countryName: 'Ireland', isRightHandDrive: true },
  C23: { countryName: 'Australia', isRightHandDrive: true },
  C26: { countryName: 'South Africa', isRightHandDrive: true },
  C2A: { countryName: 'New Zealand', isRightHandDrive: true },
  C2B: { countryName: 'Mauritius', isRightHandDrive: true },
  C45: { countryName: 'Singapur', isRightHandDrive: true },
  C46: { countryName: 'India', isRightHandDrive: true },
  C4A: { countryName: 'Kenya', isRightHandDrive: true },
  C96: { countryName: 'Trinidad and Tobago', isRightHandDrive: true },
  C98: { countryName: 'Cyprus', isRightHandDrive: true },
  C9A: { countryName: 'Barbados', isRightHandDrive: true },
  C9B: { countryName: 'Cayman Island', isRightHandDrive: true },
  C9C: { countryName: 'Jamaica', isRightHandDrive: true },
  C9D: { countryName: 'St. Lucia', isRightHandDrive: true },
  C9E: { countryName: 'Malta', isRightHandDrive: true },
}
