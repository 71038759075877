import { useEffect, useState } from 'react'
import { callBackendAPI } from '../api'
import loadEnvironment from '../loadEnvironment'
import { Bid } from '../Models/Bid'

const environment = loadEnvironment()

export function useBids(refreshKey: number = 0): [Bid[], number] {
  const [bids, setBids] = useState<Bid[]>([])
  const [lastRefreshKey, setLastRefreshKey] = useState(0)

  useEffect(() => {
    let isMounted = true

    const fetchBids = async () => {
      const response = await callBackendAPI(
        `${environment.B2B_BACKEND_BASE_URL}/api/bids`,
      )

      if (isMounted) {
        setBids(response.data)
        setLastRefreshKey(refreshKey)
      }
    }

    fetchBids()

    return () => {
      isMounted = false
    }
  }, [refreshKey])

  return [bids, lastRefreshKey]
}
