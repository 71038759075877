import {
  PButtonPure,
  PTableCell,
  PTableRow,
  PTag,
  PText,
} from '@porsche-design-system/components-react'
import styles from '../../DetailsPage/DetailsPage.module.css'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserInfo } from '../../../../App'
import {
  hasAuctionEnded,
  priceFormatting,
} from '../../../../helpers/helperFunctions'
import { useTranslation } from 'react-i18next'
import { Auction } from '../../../../Models/Auction'
import PackageVehicleImages from '../../DetailsPage/PackageDetailsPage/PackageVehicleImages'
import { FavouriteTableCell } from '../../../FavouriteAuction'
import loadEnvironment from '../../../../loadEnvironment'
import HotBiddingCell from '../HotBiddingCell'
import { Bid } from '../../../../Models/Bid'

export function getDate(dateType?: string) {
  return dateType === undefined || dateType === null
    ? '–'
    : new Date(Date.parse('' + dateType)).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
}

const LiveSalePackageRow = (props: {
  auction: Auction
  initialPrice: number
  currentHighestBid: number
  maxBidId?: string
  userMaxBid: Bid | undefined
  totalBids: number
  auctionStatus?: string
  bidListUpdaterSerial: number
  resetFn?: () => number
}) => {
  const navigate = useNavigate()
  const userInfo = useContext(UserInfo)
  const { t } = useTranslation()
  const environment = loadEnvironment()

  const { auction } = props
  const navigateToDetails = () => {
    if (!hasAuctionEnded()) return

    if (auction?.vins === undefined || auction?.vins === null) return

    navigate(`/details/${auction?.vins[0]}`)
  }

  return (
    <PTableRow role="row">
      {hasAuctionEnded() ? (
        <PTableCell>
          <PButtonPure
            icon="none"
            onClick={navigateToDetails}
            aria-label="open vehicle details"
          >
            <PackageVehicleImages vehicles={auction.vehicles} />
          </PButtonPure>
        </PTableCell>
      ) : (
        <div className={styles.displayPackageVehicleImagesRow}>
          <PackageVehicleImages vehicles={auction?.vehicles} />
        </div>
      )}
      <PTableCell>
        <PText>{auction.package_number}</PText>
      </PTableCell>
      <PTableCell>
        <PText>{auction.vins?.length || 0}</PText>
      </PTableCell>
      <PTableCell>
        {userInfo.isDealer && (
          <PText aria-label="current-highest-bid">
            {(props.currentHighestBid !== 0 &&
              priceFormatting(props.currentHighestBid)) ||
              priceFormatting(props.initialPrice)}
          </PText>
        )}
        {userInfo.isSeller && (
          <PText>{priceFormatting(Number(auction.initial_price))}</PText>
        )}
        {userInfo.isDealer && (
          <PText aria-label="user-max-bid">
            {(props.userMaxBid?.bid_amount &&
              priceFormatting(Number(props.userMaxBid.bid_amount))) ||
              '–'}
          </PText>
        )}
      </PTableCell>
      <PTableCell aria-label="total-bids">{props.totalBids}</PTableCell>
      <PTableCell aria-label="user-status">
        {userInfo.isDealer ? (
          props.userMaxBid?.bid_amount ? (
            Number(props.userMaxBid.bid_amount) > props.currentHighestBid ||
            (Number(props.userMaxBid.bid_amount) === props.currentHighestBid &&
              props.userMaxBid?._id === props.maxBidId) ? (
              <PTag color="notification-success-soft">
                {t('dealerView.highestBid')}
              </PTag>
            ) : (
              <PTag color="notification-error-soft">
                {t('dealerView.outbid')}
              </PTag>
            )
          ) : (
            <PTag color="notification-info-soft">{t('dealerView.noBid')}</PTag>
          )
        ) : (
          userInfo.isSeller && (
            <PText>
              {(props.currentHighestBid !== 0 &&
                priceFormatting(props.currentHighestBid)) ||
                '–'}
            </PText>
          )
        )}
      </PTableCell>
      {userInfo.isDealer && <FavouriteTableCell auction={props.auction} />}
      {environment.FEATURE_TOGGLES.ENABLE_HOT_BIDDING &&
        userInfo.isDealer &&
        props.auction && (
          <HotBiddingCell
            vehicle={{
              ...((props.auction.vehicles ?? [])[0] ?? {}),
              auction: props.auction,
            }}
            initialPrice={props.initialPrice}
            currentHighestBid={props.currentHighestBid}
            maxBidId={props.maxBidId}
            userMaxBid={props.userMaxBid}
            isPackageAuction={true}
            bidListUpdaterSerial={props.bidListUpdaterSerial}
            resetFn={props.resetFn}
          />
        )}
    </PTableRow>
  )
}

export default LiveSalePackageRow
