import React, { useCallback, useEffect, useState } from 'react'
import {
  PButton,
  PInlineNotification,
  PPopover,
  PText,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react'
import { useTranslation } from 'react-i18next'
import loadEnvironment from '../../../../loadEnvironment'
import styles from './BidReport.module.css'
import { callBackendAPI } from '../../../../api'
import { AxiosError, AxiosResponse } from 'axios'
import { ErrorSerializer } from '../../../../Models/ErrorSerializer'

export default function BidReport() {
  const { t } = useTranslation()
  const environment = loadEnvironment()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [errorHeading, setErrorHeading] = useState<string | null>(null)
  const [errorDescription, setErrorDescription] = useState<string | null>(null)
  const [startDateError, setStartDateError] = useState('')
  const [endDateError, setEndDateError] = useState('')
  const today = new Date().toISOString().split('T')[0]

  const isDateInFuture = (date: string | number | Date) => {
    return new Date(date) > new Date()
  }

  const exceedsMaxPeriod = (
    startDate: string | number | Date,
    endDate: string | number | Date,
  ) => {
    return (
      (new Date(endDate).getTime() - new Date(startDate).getTime()) /
        (1000 * 60 * 60 * 24) >
      91
    )
  }

  useEffect(() => {
    const disabled =
      !startDate ||
      !endDate ||
      isDateInFuture(startDate) ||
      isDateInFuture(endDate) ||
      new Date(startDate) > new Date(endDate) ||
      exceedsMaxPeriod(startDate, endDate)
    setDisabled(disabled)
  }, [startDate, endDate, disabled])

  useEffect(() => {
    if (isDateInFuture(startDate)) {
      setStartDateError(t('adminView.startDateCannotBeInTheFuture'))
    } else if (exceedsMaxPeriod(startDate, endDate)) {
      setStartDateError(t('adminView.exceedsMaxPeriodBetweenDates'))
    } else {
      setStartDateError('')
    }

    if (isDateInFuture(endDate)) {
      setEndDateError(t('adminView.endDateCannotBeInTheFuture'))
    } else if (exceedsMaxPeriod(startDate, endDate)) {
      setEndDateError(t('adminView.exceedsMaxPeriodBetweenDates'))
    } else {
      setEndDateError('')
    }
  }, [startDate, endDate, t])

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedStartDate = event.target.value
    setStartDate(selectedStartDate)
  }

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedEndDate = event.target.value
    setEndDate(selectedEndDate)
  }

  const downloadData = useCallback(async () => {
    setLoading(true)

    callBackendAPI(
      `${environment.B2B_BACKEND_BASE_URL}/api/bid-report.csv?fromDate=${startDate}&toDate=${endDate}`,
    )
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute('download', `bid-report.csv`)
          document.body.appendChild(link)
          link.click()
          link.parentNode!.removeChild(link)
          setErrorHeading(null)
          setErrorDescription(null)
        } else {
          setErrorHeading(t(`exceptions.unexpectedError`))
          setErrorDescription(`Trace ID: ${response.headers['x-trace-id']}`)
        }
      })
      .catch((error: AxiosError) => {
        const axiosErrorResponse = (error as AxiosError<ErrorSerializer>)
          .response
        const translationKey = axiosErrorResponse?.data.translationKey
        setErrorHeading(t(`exceptions.${translationKey || 'unexpectedError'}`))
        if (!translationKey) {
          setErrorDescription(
            `Trace ID: ${axiosErrorResponse?.headers['x-trace-id']}`,
          )
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [environment.B2B_BACKEND_BASE_URL, startDate, endDate, t])

  return (
    <>
      <PText size="medium" weight="semi-bold" className={styles.reportTitle}>
        {t('adminView.bidReportTitle')}
        <PPopover className={styles.popover}>
          {t('adminView.bidReportHint')}
        </PPopover>
      </PText>
      {errorHeading && (
        <PInlineNotification
          className={styles.inlineNotification}
          state="error"
          heading={errorHeading.toString()}
          description={errorDescription?.toString()}
          onDismiss={() => {
            setErrorHeading(null)
            setErrorDescription(null)
          }}
        />
      )}
      <div className={styles.formatDivAsColumn}>
        <div className={styles.formatDivAsRow}>
          <PTextFieldWrapper
            className={styles.textField}
            label={t('adminView.startDate')}
            state={startDateError ? 'error' : 'none'}
            message={startDateError}
          >
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              max={today}
            />
          </PTextFieldWrapper>
          <PTextFieldWrapper
            className={styles.textField}
            label={t('adminView.endDate')}
            state={endDateError ? 'error' : 'none'}
            message={endDateError}
          >
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              max={today}
            />
          </PTextFieldWrapper>
          <PButton
            className={styles.exportButton}
            variant="primary"
            disabled={disabled}
            onClick={downloadData}
            loading={loading}
          >
            {t('sellerView.exportButton')}
          </PButton>
        </div>
      </div>
    </>
  )
}
