import styles from './DetailsPage.module.css'
import {
  determineVehicleStatus,
  VEHICLE_STATUS,
} from '../../../helpers/vehicleStatus'
import {
  PButtonPure,
  PHeading,
  PIcon,
  PLinkPure,
  PTag,
  PText,
} from '@porsche-design-system/components-react'
import { ImageGallery } from './ImageGallery'
import React, { useContext } from 'react'
import { Vehicle } from '../../../Models/Vehicle'
import { useNavigate } from 'react-router-dom'
import { UserInfo } from '../../../App'
import { calculateAuctionEnd } from '../../AuctionCountdown'
import {
  kilometerFormatting,
  priceFormatting,
} from '../../../helpers/helperFunctions'
import { getDate } from '../LiveSaleTab/LiveSaleSingleAuctions/LiveSaleVehicleRow'
import { useTranslation } from 'react-i18next'
import { Bid } from '../../../Models/Bid'
import { determinePackageStatus } from './PackageDetailsPage/PackageSummaryBlock'
import { CountryTag } from '../../CountryTag/CountryTag'

export const createDetailsRow = (
  key: string,
  value?: string | number | JSX.Element,
) => (
  <div className={styles.row}>
    <div className={styles.title}>
      <PText color="contrast-medium" weight="semi-bold">
        {key}
      </PText>
    </div>
    <div className={styles.value}>
      <PText>{value || '–'}</PText>
    </div>
  </div>
)

export const SummaryBlock = (props: {
  vehicleWithDetails: Vehicle | null
  currentHighestBid: number
  maxBidId: string | undefined
  userMaxBid: Bid | undefined
}) => {
  const { t } = useTranslation()
  const { isSeller, isDealer } = useContext(UserInfo)
  const navigate = useNavigate()

  const { currentHighestBid, maxBidId } = props

  const status = determineVehicleStatus(
    props.vehicleWithDetails?.primary_status,
    props.vehicleWithDetails?.secondary_status,
    Boolean(props.vehicleWithDetails?.blocked_for_sales),
    props.vehicleWithDetails?.auction,
    isSeller,
    isDealer,
    props.vehicleWithDetails?.has_a_complete_auction,
  )
  const isPackageAuction =
    determinePackageStatus(props.vehicleWithDetails?.auction) !== '–'

  const detailsTableView = (
    <div className={styles.summaryDetails}>
      {createDetailsRow(
        t('sharedView.vehicleModel').toString(),
        props.vehicleWithDetails?.derivative,
      )}
      {createDetailsRow(t('sharedView.vehicleType').toString(), 'Werkswagen')}
      {createDetailsRow(
        t('sharedView.vehicleFirstRegistration').toString(),
        getDate(props.vehicleWithDetails?.date_of_first_registration),
      )}
      {createDetailsRow(
        t('sharedView.vehicleMileage').toString(),
        props.vehicleWithDetails?.mileage &&
          kilometerFormatting(props.vehicleWithDetails?.mileage),
      )}
      {createDetailsRow(
        t('sharedView.vehicleExteriorColor').toString(),
        props.vehicleWithDetails?.exterior_color?.description,
      )}
      {createDetailsRow(
        t('sharedView.vehicleInteriorColor').toString(),
        props.vehicleWithDetails?.interior_color?.description,
      )}
      {createDetailsRow(
        t('sharedView.vehicleIdentificator').toString(),
        props.vehicleWithDetails?.vin,
      )}
      {!isPackageAuction &&
        isSeller &&
        createDetailsRow(
          'Status',
          isSeller && (
            <PTag
              color={`notification-${
                status === VEHICLE_STATUS.previously_auctioned
                  ? 'warning-soft'
                  : 'info-soft'
              }`}
            >
              {t(status)}
            </PTag>
          ),
        )}
      {!isPackageAuction &&
        status === 'dealerSale' &&
        isDealer &&
        createDetailsRow(
          t('dealerView.myStatus').toString(),
          props.userMaxBid ? (
            Number(props.userMaxBid.bid_amount) > currentHighestBid ||
            (Number(props.userMaxBid.bid_amount) === currentHighestBid &&
              props.userMaxBid._id === maxBidId) ? (
              <PTag color="notification-success-soft">
                {t('dealerView.highestBid')}
              </PTag>
            ) : (
              <PTag color="notification-error-soft">
                {t('dealerView.outbid')}
              </PTag>
            )
          ) : (
            <PTag color="notification-info-soft">{t('dealerView.noBid')}</PTag>
          ),
        )}
      {isSeller &&
        (status === VEHICLE_STATUS.zulauf ||
          status === VEHICLE_STATUS.returned ||
          status === VEHICLE_STATUS.blocked) &&
        createDetailsRow(
          t('sharedView.vehicleReturnDate').toString(),
          getDate(props.vehicleWithDetails?.date_of_return),
        )}
      {isPackageAuction &&
        createDetailsRow(
          t('sellerView.singlePrice').toString(),
          props.vehicleWithDetails
            ? priceFormatting(Number(props.vehicleWithDetails?.initial_price)) +
                ` (${t('sharedView.net')})`
            : '-',
        )}
      {!isPackageAuction &&
        status === 'dealerSale' &&
        isDealer &&
        (currentHighestBid
          ? createDetailsRow(
              t('dealerView.currentHighestBid').toString(),
              priceFormatting(currentHighestBid) + ` (${t('sharedView.net')})`,
            )
          : createDetailsRow(
              t('sharedView.vehicleInitialPrice').toString(),
              props.vehicleWithDetails?.auction
                ? priceFormatting(
                    Number(props.vehicleWithDetails?.auction?.initial_price),
                  ) + ` (${t('sharedView.net')})`
                : '-',
            ))}
      {!isPackageAuction &&
        status !== VEHICLE_STATUS.preview &&
        status !== 'dealerSale' &&
        isDealer &&
        (currentHighestBid
          ? createDetailsRow(
              t('dealerView.finalPrice').toString(),
              priceFormatting(currentHighestBid) + ` (${t('sharedView.net')})`,
            )
          : createDetailsRow(
              t('sharedView.vehicleInitialPrice').toString(),
              props.vehicleWithDetails?.auction
                ? priceFormatting(
                    Number(props.vehicleWithDetails?.auction?.initial_price),
                  ) + ` (${t('sharedView.net')})`
                : '-',
            ))}

      {!isPackageAuction &&
        status === 'dealerSale' &&
        isDealer &&
        createDetailsRow(
          t('dealerView.myMaximumBid').toString(),
          props.userMaxBid
            ? priceFormatting(Number(props.userMaxBid.bid_amount)) +
                ` (${t('sharedView.net')})`
            : '-',
        )}
      {!isPackageAuction &&
        status !== VEHICLE_STATUS.preview &&
        createDetailsRow(
          t('sharedView.auctionEndDate').toString(),
          props.vehicleWithDetails?.auction
            ? t(calculateAuctionEnd(props.vehicleWithDetails?.auction))
            : '-',
        )}
      {!isPackageAuction &&
        status === VEHICLE_STATUS.preview &&
        createDetailsRow(
          t('sharedView.auctionStartDate').toString(),
          getDate(props.vehicleWithDetails?.auction?.start_date),
        )}
      {createDetailsRow(
        t('sharedView.vehicleListingPrice').toString(),
        priceFormatting(props.vehicleWithDetails?.gross_price || 0) +
          ` (${t('sharedView.gross')})`,
      )}
      {!isPackageAuction &&
        (status === VEHICLE_STATUS.preview || isSeller) &&
        createDetailsRow(
          t('sharedView.vehicleInitialPrice').toString(),
          props.vehicleWithDetails?.bundle_auction
            ? props.vehicleWithDetails.initial_price
              ? priceFormatting(props.vehicleWithDetails?.initial_price) +
                ` (${t('sharedView.net')})`
              : '-'
            : props.vehicleWithDetails?.auction?.initial_price
              ? priceFormatting(
                  parseFloat(props.vehicleWithDetails?.auction.initial_price),
                ) + ` (${t('sharedView.net')})`
              : '-',
        )}
      {!isPackageAuction &&
        isSeller &&
        status === VEHICLE_STATUS.sale &&
        createDetailsRow(
          `Live ${t('sellerView.vehiclePriceColumn')}`,
          currentHighestBid
            ? priceFormatting(currentHighestBid) + ` (${t('sharedView.net')})`
            : '-',
        )}
      {!isPackageAuction &&
        isSeller &&
        status === VEHICLE_STATUS.sale &&
        createDetailsRow(
          t('sharedView.bidsAmount').toString(),
          props.vehicleWithDetails?.auction?.total_bids,
        )}
      {status !== VEHICLE_STATUS.blocked &&
        status !== VEHICLE_STATUS.zulauf &&
        createDetailsRow(
          t('sharedView.vehicleDamageValue'),
          props.vehicleWithDetails?.bundle_auction
            ? props.vehicleWithDetails?.repaired_damage_value ||
              props.vehicleWithDetails?.repaired_damage_value === 0
              ? priceFormatting(
                  props.vehicleWithDetails?.repaired_damage_value,
                ) + ` (${t('sharedView.net')})`
              : '-'
            : props.vehicleWithDetails?.auction?.repaired_damage_value ||
                // eslint-disable-next-line eqeqeq
                props.vehicleWithDetails?.auction?.repaired_damage_value == '0'
              ? priceFormatting(
                  parseFloat(
                    props.vehicleWithDetails?.auction?.repaired_damage_value,
                  ),
                ) + ` (${t('sharedView.net')})`
              : '-',
        )}

      {status !== VEHICLE_STATUS.blocked &&
        status !== VEHICLE_STATUS.zulauf &&
        createDetailsRow(
          `KVA`,
          props.vehicleWithDetails?.bundle_auction
            ? props.vehicleWithDetails?.estimated_damage_cost ||
              props.vehicleWithDetails?.estimated_damage_cost === 0
              ? priceFormatting(
                  props.vehicleWithDetails?.estimated_damage_cost,
                ) + ` (${t('sharedView.net')})`
              : '-'
            : props.vehicleWithDetails?.auction?.estimated_damage_cost ||
                // eslint-disable-next-line eqeqeq
                props.vehicleWithDetails?.auction?.estimated_damage_cost == '0'
              ? priceFormatting(
                  parseFloat(
                    props.vehicleWithDetails?.auction?.estimated_damage_cost,
                  ),
                ) + ` (${t('sharedView.net')})`
              : '-',
        )}
      {status !== VEHICLE_STATUS.blocked &&
        status !== VEHICLE_STATUS.zulauf &&
        createDetailsRow(
          t('sharedView.vehicleNotes').toString(),
          props.vehicleWithDetails?.bundle_auction
            ? props.vehicleWithDetails?.notes || '-'
            : props.vehicleWithDetails?.auction?.notes
              ? props.vehicleWithDetails?.auction?.notes
              : '-',
        )}
      {status !== VEHICLE_STATUS.blocked &&
        status !== VEHICLE_STATUS.zulauf &&
        createDetailsRow(
          t('sharedView.auctionUrl').toString(),
          props.vehicleWithDetails?.bundle_auction ? (
            props.vehicleWithDetails?.url ? (
              <PLinkPure
                icon="external"
                size="small"
                href={props.vehicleWithDetails.url}
                target="_blank"
                rel="noreferrer"
              >
                {t('sharedView.clickUrl')}
              </PLinkPure>
            ) : (
              '-'
            )
          ) : props.vehicleWithDetails?.auction?.url ? (
            <PLinkPure
              icon="external"
              size="small"
              href={props.vehicleWithDetails.auction.url}
              target="_blank"
              rel="noreferrer"
            >
              {t('sharedView.clickUrl')}
            </PLinkPure>
          ) : (
            '-'
          ),
        )}
      {status !== VEHICLE_STATUS.blocked &&
        status !== VEHICLE_STATUS.zulauf &&
        createDetailsRow(
          t('sharedView.extraTyresKey').toString(),
          props.vehicleWithDetails?.extra_tyres ? (
            <PIcon name="check" size="small" />
          ) : (
            '-'
          ),
        )}
    </div>
  )

  return (
    <>
      <div className={styles.detailBlock}>
        <div className={styles.summaryHeader}>
          <PHeading tag="h3" size="large">
            {t('sharedView.summaryBlockTitle')}
            {isPackageAuction ? (
              <CountryTag
                className={styles.summaryTag}
                countryCode={
                  props.vehicleWithDetails?.details?.country_specification
                }
              />
            ) : (
              <></>
            )}
            {isPackageAuction && props.vehicleWithDetails?.extra_tyres ? (
              <PTag className={styles.summaryTag} icon="snowflake">
                {t('sharedView.extraTyres')}
              </PTag>
            ) : (
              <></>
            )}
          </PHeading>
          {(status === VEHICLE_STATUS.returned ||
            status === VEHICLE_STATUS.previously_auctioned ||
            status === VEHICLE_STATUS.ready) &&
            !(
              props.vehicleWithDetails?.auction &&
              Date.parse(props.vehicleWithDetails?.auction?.start_date) <
                Date.now() &&
              Date.now() <
                Date.parse(props.vehicleWithDetails?.auction?.end_date)
            ) &&
            !isPackageAuction &&
            isSeller && (
              <PButtonPure
                icon="edit"
                aria-label="edit auction"
                onClick={() =>
                  navigate(`/details/${props.vehicleWithDetails?.vin}/auction`)
                }
              >
                {t('sellerView.editAuctionButton')}
              </PButtonPure>
            )}
        </div>

        <div className={styles.summaryContent}>
          <ImageGallery imgGallery={props.vehicleWithDetails?.gallery} />
          {detailsTableView}
        </div>
      </div>
    </>
  )
}
