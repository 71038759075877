import { AVAILABLE_MODEL_FILTERS } from './FilterConstants'
import SelectModel from './SelectModel'
import { PTagDismissible } from '@porsche-design-system/components-react'
import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './FilterRow.module.css'
import VinSearchInput from './VinSearchInput'
import SelectAuctionBundleStatus from './SelectAuctionBundleStatus'
import SelectDerivative from './SelectDerivative'

const ReturnedVehiclesFilterRow = (props: {
  blockedForSales: boolean
  onChangeVinSearchInput: Dispatch<SetStateAction<string>>
  vinSearchInput: string
  onChangeModel: Dispatch<SetStateAction<string>>
  modelFilter: string
  onChangeDerivative: Dispatch<SetStateAction<string[]>>
  derivativeFilter: string[]
  onChangeSelectAuctionBundleStatus: Dispatch<SetStateAction<string>>
  selectAuctionBundleStatus: string
}) => {
  const { t } = useTranslation()

  const forceDeselectModelFilter = useCallback(
    () => props.onChangeModel(AVAILABLE_MODEL_FILTERS[0]),
    [props],
  )

  const forceDeselectAuctionBundleStatus = useCallback(
    () => props.onChangeSelectAuctionBundleStatus(''),
    [props],
  )

  const displayPTagDismissible = (
    tagText: string,
    deselectFilter: () => void,
  ) => {
    return (
      <PTagDismissible
        color="background-surface"
        onClick={() => deselectFilter()}
        className={styles.tags}
      >
        {tagText}
      </PTagDismissible>
    )
  }

  const modelFilterSelected = props.modelFilter !== AVAILABLE_MODEL_FILTERS[0]

  const auctionBundleStatusSelected = props.selectAuctionBundleStatus !== ''

  return (
    <div className={styles.filterRowWrapper}>
      <div className={styles.displayInARow}>
        <VinSearchInput
          onChange={props.onChangeVinSearchInput}
          vinSearchInput={props.vinSearchInput}
        />
        <SelectModel
          onChange={props.onChangeModel}
          modelFilter={props.modelFilter}
        />
        <SelectDerivative
          onChange={props.onChangeDerivative}
          selectedModel={props.modelFilter}
          derivativeFilter={props.derivativeFilter}
        />
        {!props.blockedForSales && (
          <>
            <SelectAuctionBundleStatus
              onChange={props.onChangeSelectAuctionBundleStatus}
              bundleFilter={props.selectAuctionBundleStatus}
            />
          </>
        )}
      </div>
      <div className={styles.displayInARow}>
        {modelFilterSelected &&
          displayPTagDismissible(props.modelFilter, forceDeselectModelFilter)}
        {!props.blockedForSales &&
          auctionBundleStatusSelected &&
          displayPTagDismissible(
            {
              SINGLE: t('sharedView.singleAuction'),
              PACKAGE: t('sharedView.packageAuction'),
              NEW: t('sharedView.newVehicles'),
            }[props.selectAuctionBundleStatus] || t('sharedView.allAuctions'),
            forceDeselectAuctionBundleStatus,
          )}
      </div>
    </div>
  )
}

export default ReturnedVehiclesFilterRow
