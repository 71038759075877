type FeatureToggleMapping = {
  SHOW_INCOMING_VEHICLES_TAB: boolean
  ENABLE_HOT_BIDDING: boolean
  ENABLE_FEEDBACK_FOR_DEALERS: boolean
  ENABLE_FEEDBACK_FOR_SELLERS: boolean
}

type EnvironmentConfig = {
  AWS_REGION: string
  COGNITO_DOMAIN: string
  COGNITO_USER_POOL_ID: string
  COGNITO_CLIENT_ID: string
  B2B_BACKEND_BASE_URL: string
  CUSTOM_PROVIDER: string
  SELLER_GROUP_NAME: string
  ADMIN_GROUP_NAME: string
  AUCTION_START_TIME: string
  AUCTION_END_TIME: string
  FEATURE_TOGGLES: FeatureToggleMapping
}

const loadEnvironment = (): EnvironmentConfig => {
  switch (process.env.REACT_APP_ENV) {
    case 'test':
      return {
        AWS_REGION: 'eu-west-1',
        COGNITO_DOMAIN:
          'honeypotters-setup-b2b-staging.auth.eu-west-1.amazoncognito.com',
        COGNITO_USER_POOL_ID: 'eu-west-1_stlbh3Ftj',
        COGNITO_CLIENT_ID: '318mac6q4ekvm0sbdnts1qqd74',
        B2B_BACKEND_BASE_URL:
          'https://api.b2b-pag.aws.platform.porsche-preview.cloud',
        CUSTOM_PROVIDER: 'ppn-lite',
        SELLER_GROUP_NAME: 'sellers',
        ADMIN_GROUP_NAME: 'admins',
        AUCTION_START_TIME: '9',
        AUCTION_END_TIME: '17',
        FEATURE_TOGGLES: {
          SHOW_INCOMING_VEHICLES_TAB: true,
          ENABLE_HOT_BIDDING: true,
          ENABLE_FEEDBACK_FOR_DEALERS: true,
          ENABLE_FEEDBACK_FOR_SELLERS: true,
        },
      }
    case 'development':
      return {
        AWS_REGION: 'eu-west-1',
        COGNITO_DOMAIN:
          'honeypotters-setup-b2b-staging.auth.eu-west-1.amazoncognito.com',
        COGNITO_USER_POOL_ID: 'eu-west-1_stlbh3Ftj',
        COGNITO_CLIENT_ID: '318mac6q4ekvm0sbdnts1qqd74',
        B2B_BACKEND_BASE_URL:
          'https://api.b2b-pag.aws.platform.porsche-preview.cloud',
        CUSTOM_PROVIDER: 'ppn-lite',
        SELLER_GROUP_NAME: 'sellers',
        ADMIN_GROUP_NAME: 'admins',
        AUCTION_START_TIME: '9',
        AUCTION_END_TIME: '17',
        FEATURE_TOGGLES: {
          SHOW_INCOMING_VEHICLES_TAB: false,
          ENABLE_HOT_BIDDING: true,
          ENABLE_FEEDBACK_FOR_DEALERS: true,
          ENABLE_FEEDBACK_FOR_SELLERS: true,
        },
      }
    case 'staging':
      return {
        AWS_REGION: 'eu-west-1',
        COGNITO_DOMAIN:
          'honeypotters-setup-b2b-staging.auth.eu-west-1.amazoncognito.com',
        COGNITO_USER_POOL_ID: 'eu-west-1_stlbh3Ftj',
        COGNITO_CLIENT_ID: '318mac6q4ekvm0sbdnts1qqd74',
        B2B_BACKEND_BASE_URL:
          'https://api.b2b-pag.aws.platform.porsche-preview.cloud',
        CUSTOM_PROVIDER: 'ppn-lite',
        SELLER_GROUP_NAME: 'sellers',
        ADMIN_GROUP_NAME: 'admins',
        AUCTION_START_TIME: '9',
        AUCTION_END_TIME: '17',
        FEATURE_TOGGLES: {
          SHOW_INCOMING_VEHICLES_TAB: false,
          ENABLE_HOT_BIDDING: true,
          ENABLE_FEEDBACK_FOR_DEALERS: true,
          ENABLE_FEEDBACK_FOR_SELLERS: true,
        },
      }
    case 'production':
      return {
        AWS_REGION: 'eu-west-1',
        COGNITO_DOMAIN:
          'honeypotters-setup-b2b-production.auth.eu-west-1.amazoncognito.com',
        COGNITO_USER_POOL_ID: 'eu-west-1_3xtTMyFjr',
        COGNITO_CLIENT_ID: '49bu70krc97nmsu1489ihe0j95',
        B2B_BACKEND_BASE_URL: 'https://api.b2b-pag.aws.platform.porsche.cloud',
        CUSTOM_PROVIDER: 'ppn',
        SELLER_GROUP_NAME: 'sellers',
        ADMIN_GROUP_NAME: 'admins',
        AUCTION_START_TIME: '9',
        AUCTION_END_TIME: '12',
        FEATURE_TOGGLES: {
          SHOW_INCOMING_VEHICLES_TAB: false,
          ENABLE_HOT_BIDDING: false,
          ENABLE_FEEDBACK_FOR_DEALERS: false,
          ENABLE_FEEDBACK_FOR_SELLERS: false,
        },
      }
    default:
      throw new Error(`Invalid environment`)
  }
}

export default loadEnvironment
