import React, { useCallback, useContext, useState } from 'react'
import styles from '../IncomingTab/IncomingVehiclesContent.module.css'
import { Vehicle } from '../../../Models/Vehicle'
import usePorscheCenter from '../../../Hooks/usePorscheCenter'
import { UserInfo } from '../../../App'
import BiddingModal from '../DetailsPage/BiddingModal'
import {
  PBanner,
  PButton,
  PButtonGroup,
  PPopover,
  PTableCell,
  PTableHeadCell,
  PText,
  useToastManager,
} from '@porsche-design-system/components-react'
import { Bid } from '../../../Models/Bid'
import { useTranslation } from 'react-i18next'
import { callBackendAPI } from '../../../api'
import { AxiosError } from 'axios'
import { ErrorSerializer } from '../../../Models/ErrorSerializer'
import loadEnvironment from '../../../loadEnvironment'
import {
  convertUtcTimeToGermanTime,
  priceFormatting,
} from '../../../helpers/helperFunctions'

const environment = loadEnvironment()

export const HotBiddingHead = () => {
  const { t } = useTranslation()

  return (
    <PTableHeadCell role="columnheader">
      <div className={styles.inlinePopoverContainer}>
        <PText weight="semi-bold">{t('dealerView.hotBidding')}</PText>
        <PPopover
          className={styles.popover}
          style={{ whiteSpace: 'pre-line' }}
          aria={{ 'aria-label': 'Additional information' }}
        >
          {t('dealerView.hotBiddingInfo')}
        </PPopover>
      </div>
    </PTableHeadCell>
  )
}

const HotBiddingCell: React.FC<{
  userMaxBid: Bid | undefined
  maxBidId: string | undefined
  initialPrice: number
  currentHighestBid: number
  isPackageAuction: boolean
  vehicle: Vehicle
  bidListUpdaterSerial: number
  resetFn?: () => number
}> = ({
  userMaxBid,
  maxBidId,
  initialPrice,
  currentHighestBid,
  isPackageAuction,
  vehicle,
  bidListUpdaterSerial,
  resetFn,
}) => {
  const [minimumBidListSerial, setMinimumBidListSerial] = useState(0)
  const [busy, setBusy] = useState(false)
  const [isBiddingModalOpen, setIsBiddingModalOpen] = useState(false)

  const { t } = useTranslation()
  const { userId, isDealer } = useContext(UserInfo)
  const porscheCenters = usePorscheCenter(userId, isDealer)
  const { addMessage } = useToastManager()

  const handleModalClose = () => {
    setIsBiddingModalOpen(false)
  }

  const [isErrorBannerOpen, setIsErrorBannerOpen] = useState(false)
  const [errorBannerMessage, setErrorBannerMessage] = useState('')
  const showErrorBanner = useCallback((errorMsg: string) => {
    setErrorBannerMessage(errorMsg)
    setIsErrorBannerOpen(true)
  }, [])
  const dismissErrorBanner = useCallback(() => {
    setIsErrorBannerOpen(false)
  }, [])

  const placeQuickBid = () => {
    setBusy(true)
    const minimumValidBidAmount =
      currentHighestBid !== 0
        ? userMaxBid && maxBidId === userMaxBid._id
          ? Math.max(
              currentHighestBid + 100,
              Number(userMaxBid.bid_amount) + 100,
            )
          : currentHighestBid + 100
        : Number(initialPrice)
    callBackendAPI(`${environment.B2B_BACKEND_BASE_URL}/api/bids`, 'POST', {
      auction_id: vehicle?.auction?._id,
      porsche_center_name: userMaxBid?.porsche_center_name,
      porsche_center_id: userMaxBid?.porsche_center_id,
      porsche_center_country: userMaxBid?.porsche_center_country,
      bid_amount: minimumValidBidAmount.toString(),
    })
      .then((response) => {
        if (resetFn) {
          setMinimumBidListSerial(resetFn())
        }
        if (response.status === 201 && response.data.length === 1) {
          let auctionInfo = isPackageAuction
            ? t('sharedView.packageNumber')
            : t('sharedView.vehicleIdentificator')
          auctionInfo += ': '
          auctionInfo += isPackageAuction
            ? vehicle.auction?.package_number
            : vehicle.auction?.vin

          switch (response.data[0].success) {
            case 0:
              addMessage({
                text: t('dealerView.youHaveBeenOutbidWithAuctionInfo', {
                  bidAmount: priceFormatting(minimumValidBidAmount),
                  auctionInfo: auctionInfo,
                }),
                state: 'info',
              })
              break
            case 1:
              addMessage({
                text: t('dealerView.youAreNowTheHighestBidder', {
                  bidAmount: priceFormatting(minimumValidBidAmount),
                  auctionInfo: auctionInfo,
                }),
                state: 'success',
              })
              break
            case 2:
              addMessage({
                text: t('dealerView.youRaisedYourMaxBid', {
                  bidAmount: priceFormatting(minimumValidBidAmount),
                  auctionInfo: auctionInfo,
                }),
                state: 'success',
              })
              break
            default:
              throw new Error('unknown bidding status')
          }
        } else {
          throw response
        }
      })
      .catch((error) => {
        const axiosErrorResponse = (error as AxiosError<ErrorSerializer>)
          .response
        const translationKey = axiosErrorResponse?.data.translationKey

        if (translationKey) {
          showErrorBanner(t(`exceptions.${translationKey}`))
        } else {
          showErrorBanner(
            t(`exceptions.unexpectedError`) +
              ` Trace ID: ${axiosErrorResponse?.headers['x-trace-id']}`,
          )
        }
      })
      .finally(() => {
        setTimeout(() => {
          setBusy(false)
        }, 500)
      })
  }
  const date = convertUtcTimeToGermanTime(new Date())
  const start_date = convertUtcTimeToGermanTime(
    new Date(vehicle?.auction?.start_date ?? ''),
  )
  const end_date = convertUtcTimeToGermanTime(
    new Date(vehicle?.auction?.end_date ?? ''),
  )

  return (
    <PTableCell role="cell" aria-label="hot bidding">
      {start_date <= date && end_date >= date && (
        <>
          <PButtonGroup style={{ minWidth: '164px' }}>
            <PButton
              style={{ width: '75px' }}
              variant="primary"
              disabled={!userMaxBid}
              loading={busy || bidListUpdaterSerial < minimumBidListSerial}
              onClick={() => setIsBiddingModalOpen(true)}
              aria={{ 'aria-label': 'Open Agent bidding modal' }}
            >
              Agent
            </PButton>
            <PButton
              style={{ width: '75px' }}
              variant="tertiary"
              disabled={!userMaxBid}
              loading={busy || bidListUpdaterSerial < minimumBidListSerial}
              onClick={() => placeQuickBid()}
              aria={{ 'aria-label': 'Add 100€' }}
            >
              +100
            </PButton>
          </PButtonGroup>
          {isBiddingModalOpen && (
            <BiddingModal
              vehicleWithDetails={vehicle}
              porscheCenters={porscheCenters}
              open={isBiddingModalOpen}
              closingHandler={handleModalClose}
              currentHighestBid={currentHighestBid}
              maxBidId={maxBidId}
              userMaxBid={userMaxBid}
              isPackageAuction={isPackageAuction}
              isQuickBid={true}
              resetFn={() => {
                setBusy(true)
                setTimeout(() => {
                  setBusy(false)
                }, 500)
                if (resetFn) {
                  setMinimumBidListSerial(resetFn())
                }
              }}
            />
          )}
          <PBanner
            open={isErrorBannerOpen}
            heading={t('exceptions.genericError')}
            description={errorBannerMessage}
            onDismiss={dismissErrorBanner}
            state="error"
            aria-label="Error +100 banner"
          />
        </>
      )}
    </PTableCell>
  )
}

export default HotBiddingCell
