import {
  MultiSelectUpdateEvent,
  PMultiSelect,
  PMultiSelectOption,
} from '@porsche-design-system/components-react'
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { derivativesByModel, NO_DERIVATIVE_SELECTED } from './FilterConstants'
import styles from './FilterRow.module.css'

const SelectDerivative = (props: {
  onChange: Dispatch<SetStateAction<string[]>>
  selectedModel: string
  derivativeFilter: string[]
}) => {
  const { t } = useTranslation()

  const selectOptions = derivativesByModel(props.selectedModel).map(
    (derivativeFilter) => (
      <PMultiSelectOption
        key={derivativeFilter}
        value={derivativeFilter}
        disabled={
          props.derivativeFilter.includes(NO_DERIVATIVE_SELECTED) &&
          derivativeFilter !== NO_DERIVATIVE_SELECTED
        }
      >
        {t(derivativeFilter)}
      </PMultiSelectOption>
    ),
  )

  useEffect(() => {
    if (!props.derivativeFilter.includes(NO_DERIVATIVE_SELECTED)) {
      if (
        props.derivativeFilter.filter(
          (derivative) =>
            !derivative
              .toLowerCase()
              .startsWith(props.selectedModel.toLowerCase()),
        ).length > 0
      ) {
        props.onChange([NO_DERIVATIVE_SELECTED])
      }
    } else {
      if (props.derivativeFilter.length > 1) {
        props.onChange([NO_DERIVATIVE_SELECTED])
      }
    }
  }, [props])

  const changeModelFilter = useCallback(
    (event: CustomEvent<MultiSelectUpdateEvent>) => {
      props.onChange(event.detail.value)
    },
    [props],
  )

  return (
    <>
      <PMultiSelect
        name="SelectDerivative"
        aria-label="Derivative filter"
        className={styles.selectFilter}
        onUpdate={changeModelFilter}
        value={props.derivativeFilter}
        disabled={selectOptions.length < 2}
      >
        {selectOptions}
      </PMultiSelect>
    </>
  )
}

export default SelectDerivative
