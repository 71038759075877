import axios from 'axios'
import loadEnvironment from './loadEnvironment'
import {
  AVAILABLE_MODEL_FILTERS,
  NO_DERIVATIVE_SELECTED,
} from './Components/Filters/FilterConstants'
import { STATUS_FILTER } from './Hooks/statusFilterConstants'
import { Auth } from 'aws-amplify'

const environment = loadEnvironment()

const addParamsToURL = (
  url: string,
  pageSize: number,
  modelFilter: string,
  derivativeFilter: string[],
  primaryStatusFilter: string[],
  secondaryStatusFilter: string,
  blockedForSalesFilter: boolean,
  futureAuctionsFilter: boolean,
  ignoreSoldCars: boolean,
  vinSearchInput?: string,
  auctionBundleStatus?: string,
  atDate?: string,
  favCheckboxFilter?: boolean,
) => {
  if (pageSize) {
    url += `&pageSize=${pageSize}`
  }
  if (vinSearchInput) {
    url += `&vinSearch=${vinSearchInput}`
  }

  if (modelFilter !== AVAILABLE_MODEL_FILTERS[0]) {
    url += `&models=${modelFilter.toLowerCase()}`
  }

  if (!derivativeFilter.includes(NO_DERIVATIVE_SELECTED)) {
    derivativeFilter.forEach((derivative) => {
      url += `&derivatives=${encodeURIComponent(derivative)}`
    })
  }

  if (auctionBundleStatus && auctionBundleStatus !== '') {
    url += `&bundleStatus=` + auctionBundleStatus
  }

  if (atDate && atDate !== '') {
    url += `&atDate=${atDate}`
  }

  if (favCheckboxFilter != null) {
    url += `&favouriteOnly=${favCheckboxFilter}`
  }

  primaryStatusFilter
    .filter((e) => STATUS_FILTER.includes(e))
    .map((e) => (url += `&status=${e}`))

  if (secondaryStatusFilter) url += `&secondaryStatus=${secondaryStatusFilter}`

  if (blockedForSalesFilter) {
    url += '&blocked=true'
  } else {
    url += '&blocked=false'
  }

  if (futureAuctionsFilter) {
    url += '&future=true'
  } else {
    url += '&future=false'
  }

  if (ignoreSoldCars) url += `&ignoreSoldCars=true`

  return url
}

export const createVehicleURL = (
  modelFilter: string,
  derivativeFilter: string[],
  primaryStatusFilter: string[],
  secondaryStatusFilter: string,
  blockedForSalesFilter: boolean,
  futureAuctionsFilter: boolean,
  ignoreSoldCars: boolean,
  pageIndex: number,
  pageSize: number,
  vinSearchInput?: string,
  auctionBundleStatus?: string,
  atDate?: string,
  favCheckboxFilter?: boolean,
) => {
  let vehicleURL = `${environment.B2B_BACKEND_BASE_URL}/api/vehicles?page=${pageIndex}`
  vehicleURL = addParamsToURL(
    vehicleURL,
    pageSize,
    modelFilter,
    derivativeFilter,
    primaryStatusFilter,
    secondaryStatusFilter,
    blockedForSalesFilter,
    futureAuctionsFilter,
    ignoreSoldCars,
    vinSearchInput,
    auctionBundleStatus,
    atDate,
    favCheckboxFilter,
  )
  return vehicleURL
}

export const createPackageAuctionsURL = (
  currentTab: string,
  pageIndex: number,
  pageSize: number,
) => {
  return `${environment.B2B_BACKEND_BASE_URL}/api/${currentTab}-package-auctions?page=${pageIndex}&pageSize=${pageSize}`
}

export const createVehicleDetailsURL = (vin: string) => {
  return `${environment.B2B_BACKEND_BASE_URL}/api/vehicleDetails?vin=${vin}`
}

export async function getAccessToken() {
  const currentSession = await Auth.currentSession()
  return currentSession.getAccessToken().getJwtToken()
}

export const callBackendAPI = async (
  url: string,
  method: string = 'GET',
  body?: object,
) => {
  const accessToken = await getAccessToken()

  const headers = { Authorization: `Bearer ${accessToken}` }

  let response

  if (method === 'GET') {
    response = await axios.get(url, { headers })
  } else if (method === 'POST') {
    response = await axios.post(url, body, { headers })
  } else if (method === 'PUT') {
    response = await axios.put(url, body, { headers })
  } else if (method === 'DELETE') {
    response = await axios.delete(url, { headers: headers, data: body })
  } else {
    throw new Error('Unsupported request method')
  }

  return response
}
