import { useEffect } from 'react'

const validation = (
  value: string | null,
  defaultVal: string,
  validationFn: (x: string) => boolean = () => true,
) => (value == null || !validationFn(value) ? defaultVal : value)

const readFromSessionStorage = (
  key: string,
  defaultValue: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validationFn = (_: string) => true,
) => validation(sessionStorage.getItem(key), defaultValue, validationFn)

function useSessionStorage(value: string, key: string) {
  useEffect(() => {
    sessionStorage.setItem(key, value)
  }, [value, key])
}

export { readFromSessionStorage, useSessionStorage }
