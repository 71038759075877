import {
  PButtonPure,
  PIcon,
  PPopover,
  PTableCell,
  PTableRow,
  PTag,
  PText,
} from '@porsche-design-system/components-react'
import styles from '../../IncomingTab/IncomingVehiclesContent.module.css'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Vehicle } from '../../../../Models/Vehicle'
import { UserInfo } from '../../../../App'
import {
  hasAuctionEnded,
  kilometerFormatting,
  priceFormatting,
} from '../../../../helpers/helperFunctions'
import { useTranslation } from 'react-i18next'
import { FavouriteTableCell } from '../../../FavouriteAuction'
import loadEnvironment from '../../../../loadEnvironment'
import HotBiddingCell from '../HotBiddingCell'
import { Bid } from '../../../../Models/Bid'

export function getDate(dateType?: string) {
  return dateType === undefined || dateType === null
    ? '–'
    : new Date(Date.parse('' + dateType)).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
}

const LiveSaleVehicleRow = (props: {
  vehicle: Vehicle
  initialPrice: number
  currentHighestBid: number
  maxBidId?: string
  userMaxBid: Bid | undefined
  totalBids: number
  auctionStatus?: string
  bidListUpdaterSerial: number
  resetFn?: () => number
}) => {
  const navigate = useNavigate()
  const userInfo = useContext(UserInfo)
  const { t } = useTranslation()
  const environment = loadEnvironment()

  const navigateToDetails = () => {
    navigate(`/details/${props.vehicle.vin}`)
  }

  const vehicleImage = (
    <img
      className={styles.image}
      src={props.vehicle.preview || '/Car-Preview.png'}
      alt="car preview"
    />
  )

  return (
    <PTableRow role="row">
      <PTableCell>
        {hasAuctionEnded() ? (
          <PButtonPure
            icon="none"
            onClick={navigateToDetails}
            aria-label="open vehicle details"
          >
            {vehicleImage}
          </PButtonPure>
        ) : (
          vehicleImage
        )}
      </PTableCell>
      <PTableCell>
        {props.vehicle.derivative ? (
          hasAuctionEnded() ? (
            <PButtonPure weight="bold" icon="none" onClick={navigateToDetails}>
              <u>{props.vehicle.derivative}</u>
              {props.vehicle.extra_tyres ? (
                <PIcon name="snowflake" size="small"></PIcon>
              ) : (
                <></>
              )}
            </PButtonPure>
          ) : (
            <>
              <b>{props.vehicle.derivative}</b>
              {props.vehicle.extra_tyres ? (
                <PIcon name="snowflake" size="small"></PIcon>
              ) : (
                <></>
              )}
            </>
          )
        ) : (
          <PText>{'–'}</PText>
        )}
        <PText>{props.vehicle.vin}</PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {props.vehicle.exterior_color?.name}
          <PPopover
            className={styles.popover}
            direction="top"
            description={props.vehicle.exterior_color?.description}
          />
        </PText>
        <PText>
          {props.vehicle.interior_color?.name}
          <PPopover
            className={styles.popover}
            direction="top"
            description={props.vehicle.interior_color?.description}
          />
        </PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {getDate(props.vehicle.date_of_first_registration) || '–'}
        </PText>
        <PText>
          {(props.vehicle.mileage &&
            kilometerFormatting(props.vehicle.mileage)) ||
            '–'}
        </PText>
      </PTableCell>
      <PTableCell>
        {userInfo.isDealer && (
          <PText aria-label="current-highest-bid">
            {(props.currentHighestBid !== 0 &&
              priceFormatting(props.currentHighestBid)) ||
              priceFormatting(props.initialPrice)}
          </PText>
        )}
        {userInfo.isSeller && (
          <PText>
            {priceFormatting(Number(props.vehicle.auction?.initial_price))}
          </PText>
        )}
        {userInfo.isDealer && (
          <PText aria-label="user-max-bid">
            {(props.userMaxBid &&
              priceFormatting(Number(props.userMaxBid?.bid_amount))) ||
              '–'}
          </PText>
        )}
      </PTableCell>
      <PTableCell aria-label="total-bids">{props.totalBids}</PTableCell>
      <PTableCell aria-label="user-status">
        {userInfo.isDealer ? (
          props.userMaxBid ? (
            Number(props.userMaxBid?.bid_amount) > props.currentHighestBid ||
            (Number(props.userMaxBid?.bid_amount) === props.currentHighestBid &&
              props.userMaxBid?._id === props.maxBidId) ? (
              <PTag color="notification-success-soft">
                {t('dealerView.highestBid')}
              </PTag>
            ) : (
              <PTag color="notification-error-soft">
                {t('dealerView.outbid')}
              </PTag>
            )
          ) : (
            <PTag color="notification-info-soft">{t('dealerView.noBid')}</PTag>
          )
        ) : (
          userInfo.isSeller && (
            <PText>
              {(props.currentHighestBid !== 0 &&
                priceFormatting(props.currentHighestBid)) ||
                '–'}
            </PText>
          )
        )}
      </PTableCell>
      {userInfo.isDealer && props.vehicle.auction && (
        <FavouriteTableCell auction={props.vehicle.auction} />
      )}
      {environment.FEATURE_TOGGLES.ENABLE_HOT_BIDDING &&
        userInfo.isDealer &&
        props.vehicle.auction && (
          <HotBiddingCell
            vehicle={props.vehicle}
            initialPrice={props.initialPrice}
            currentHighestBid={props.currentHighestBid}
            maxBidId={props.maxBidId}
            userMaxBid={props.userMaxBid}
            isPackageAuction={false}
            bidListUpdaterSerial={props.bidListUpdaterSerial}
            resetFn={props.resetFn}
          />
        )}
    </PTableRow>
  )
}

export default LiveSaleVehicleRow
