import {
  PButtonPure,
  PPopover,
  PTableCell,
  PTableRow,
  PText,
} from '@porsche-design-system/components-react'
import styles from './IncomingVehiclesContent.module.css'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Vehicle } from '../../../Models/Vehicle'
import { kilometerFormatting } from '../../../helpers/helperFunctions'

function getDate(item: Vehicle) {
  return item.date_of_return === undefined || item.date_of_return === null
    ? '—'
    : new Date(Date.parse('' + item.date_of_return)).toLocaleDateString(
        'de-DE',
        {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        },
      )
}

const IncomingVehicleRow = (props: { vehicle: Vehicle }) => {
  const vin = props.vehicle.vin
  const navigate = useNavigate()

  const navigateToDetails = () => {
    navigate(`/details/${vin}`)
  }

  return (
    <PTableRow role="row">
      <PTableCell>
        <PButtonPure
          icon="none"
          onClick={navigateToDetails}
          aria-label="open vehicle details"
        >
          <img
            className={styles.image}
            src={props.vehicle.preview || '/Car-Preview.png'}
            alt="Vehicle"
          />
        </PButtonPure>
      </PTableCell>
      <PTableCell>
        {props.vehicle.derivative ? (
          <PButtonPure weight="bold" icon="none" onClick={navigateToDetails}>
            <u>{props.vehicle.derivative}</u>
          </PButtonPure>
        ) : (
          <PText>{'–'}</PText>
        )}
        <PText>{vin}</PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {props.vehicle.exterior_color?.name}
          <PPopover
            className={styles.popover}
            direction="top"
            description={props.vehicle.exterior_color?.description}
          />
        </PText>
        <PText>
          {props.vehicle.interior_color?.name}
          <PPopover
            className={styles.popover}
            direction="top"
            description={props.vehicle.interior_color?.description}
          />
        </PText>
      </PTableCell>
      <PTableCell>
        {(props.vehicle.mileage &&
          kilometerFormatting(props.vehicle.mileage)) ||
          '—'}
      </PTableCell>
      <PTableCell>
        <PText>{getDate(props.vehicle)}</PText>
      </PTableCell>
    </PTableRow>
  )
}

export default IncomingVehicleRow
