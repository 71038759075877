import { useEffect, useState } from 'react'
import { Auction } from '../Models/Auction'
import { callBackendAPI, createPackageAuctionsURL } from '../api'

export const TAB_READY_FOR_SALES = 'future'
export const TAB_LIVE_SALE = 'live'
export default function usePackageAuctions(
  currentTab: string,
  pageIndex: number,
  pageSize: number = 5,
) {
  const [auctions, setAuctions] = useState<Auction[]>([])
  const [totalAuctions, setTotalAuctions] = useState(0)

  useEffect(() => {
    let isMounted = true

    const fetchAuctions = async () => {
      const packageAuctionsURL = createPackageAuctionsURL(
        currentTab,
        pageIndex,
        pageSize,
      )
      const response = await callBackendAPI(packageAuctionsURL)

      if (isMounted) {
        setAuctions(response.data.auctions)
        setTotalAuctions(response.data.total)
      }
    }

    fetchAuctions()

    return () => {
      isMounted = false
    }
  }, [currentTab, pageIndex, pageSize])

  return { auctions, totalAuctions }
}
