import {
  PButton,
  PButtonGroup,
  PButtonPure,
  PHeading,
  PInlineNotification,
  PPopover,
  PSwitch,
  PText,
  PTextareaWrapper,
  PTextFieldWrapper,
  useToastManager,
} from '@porsche-design-system/components-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { callBackendAPI } from '../../../api'
import useVehicleDetails from '../../../Hooks/useVehicleDetails'
import loadEnvironment from '../../../loadEnvironment'
import styles from '../DetailsPage/DetailsPage.module.css'
import tabsBarStyle from '../../TabsBar/TabsBar.module.css'
import { useTranslation } from 'react-i18next'
import { convertUtcTimeToGermanTime } from '../../../helpers/helperFunctions'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import {
  validInitialPrice,
  validStartDate,
  startDateIsNotOnWeekend,
} from '../../../helpers/formValidations'
import { CalculatorBlock } from './CalculatorBlock'
import type { SwitchUpdateEvent } from '@porsche-design-system/components-react'
import { Auction } from '../../../Models/Auction'

const environment = loadEnvironment()

const transformFloatToNumberFormatValues = (floatNumber: number) => {
  return {
    formattedValue: new Intl.NumberFormat('de-DE').format(floatNumber),
    floatValue: floatNumber,
  } as NumberFormatValues
}
const AuctionPage = () => {
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const { addMessage } = useToastManager()

  const handleSwitches = useCallback(
    async (showroom: boolean, reserved: string, crash: boolean) => {
      const switchRequest = () =>
        callBackendAPI(
          `${environment.B2B_BACKEND_BASE_URL}/api/vehicles/switches`,
          'PUT',
          {
            vin: params.vin,
            showroom: showroom,
            reserved: reserved,
            crash: crash,
          },
        )
      try {
        const switchResponse = await switchRequest()

        if (switchResponse.status !== 200) {
          console.error(`Failed to update vehicle's data`)
        }
        // todo: show success message
      } catch (error) {
        console.error(error)
        // todo: handle error
      }
    },
    [params.vin],
  )

  const [vehicleDetails] = useVehicleDetails(params.vin)

  const [showroom, setShowroom] = useState<boolean>(false)
  const [reserved, setReserved] = useState<string>('')
  const [crash, setCrash] = useState<boolean>(false)
  const onUpdateShowroom = useCallback(
    (e: CustomEvent<SwitchUpdateEvent>) => {
      setShowroom(e.detail.checked)
      handleSwitches(e.detail.checked, reserved, crash)
    },
    [handleSwitches, reserved, crash],
  )

  const clearAuctionDate = useCallback(
    async (auctionWithData: Auction | undefined) => {
      if (auctionWithData !== undefined) {
        const clearDateRequest = () =>
          callBackendAPI(
            `${environment.B2B_BACKEND_BASE_URL}/api/auctions/remove-date?auctionId=${auctionWithData._id}`,
            'PUT',
          )
        await clearDateRequest()
      }
    },
    [],
  )

  const onUpdateReserved = useCallback(
    (e: CustomEvent<SwitchUpdateEvent>) => {
      if (e.detail.checked && !reserved) {
        setStartDate('')
        const auctionWithData = [vehicleDetails?.auction].find(
          (item) =>
            item &&
            (convertUtcTimeToGermanTime(new Date(item.start_date)) >
              convertUtcTimeToGermanTime(new Date()) ||
              item.start_date === null),
        )
        clearAuctionDate(auctionWithData)
      }
      setReserved((prevState: string) =>
        e.detail.checked ? prevState || new Date().toISOString() : '',
      )
      handleSwitches(
        showroom,
        e.detail.checked ? reserved || new Date().toISOString() : '',
        crash,
      )
    },
    [
      clearAuctionDate,
      handleSwitches,
      showroom,
      reserved,
      crash,
      vehicleDetails?.auction,
    ],
  )
  const onUpdateCrash = useCallback(
    (e: CustomEvent<SwitchUpdateEvent>) => {
      setCrash(e.detail.checked)
      handleSwitches(showroom, reserved, e.detail.checked)
    },
    [handleSwitches, showroom, reserved],
  )
  const onUpdateExtraTyres = (e: CustomEvent<SwitchUpdateEvent>) => {
    setExtraTyres(e.detail.checked)
  }
  const [initialPrice, setInitialPrice] = useState({} as NumberFormatValues)
  const [repairedDamageValue, setRepairedDamageValue] = useState(
    {} as NumberFormatValues,
  )
  const [estimatedDamageCost, setEstimatedDamageCost] = useState(
    {} as NumberFormatValues,
  )
  const [notes, setNotes] = useState('')
  const [url, setUrl] = useState('')
  const [startDate, setStartDate] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [submitFailed, setSubmitFailed] = useState(false)
  const [bundleAuction, setBundleAuction] = useState<boolean | null>(null)
  const [extraTyres, setExtraTyres] = useState<boolean | null>(null)

  useEffect(() => {
    if (vehicleDetails) {
      const isBundle = Boolean(vehicleDetails.bundle_auction)

      setBundleAuction(isBundle)

      if (isBundle) {
        setInitialPrice(
          transformFloatToNumberFormatValues(
            Number(vehicleDetails.initial_price),
          ),
        )
        setRepairedDamageValue(
          transformFloatToNumberFormatValues(
            Number(vehicleDetails.repaired_damage_value),
          ),
        )
        setEstimatedDamageCost(
          transformFloatToNumberFormatValues(
            Number(vehicleDetails.estimated_damage_cost),
          ),
        )
        setKvaFn?.(
          transformFloatToNumberFormatValues(
            Number(vehicleDetails.estimated_damage_cost),
          ),
        )
        setNotes(`${vehicleDetails.notes}`)
        setUrl(`${vehicleDetails.url}`)
      } else {
        const auctionWithData = vehicleDetails.auction
        if (auctionWithData) {
          setInitialPrice(
            transformFloatToNumberFormatValues(
              Number(auctionWithData.initial_price),
            ),
          )
          if (
            convertUtcTimeToGermanTime(new Date(auctionWithData.start_date)) >
              convertUtcTimeToGermanTime(new Date()) ||
            auctionWithData.start_date === null
          ) {
            setStartDate(
              auctionWithData.start_date !== null
                ? auctionWithData.start_date.split('T')[0]
                : '',
            )
          }
          setRepairedDamageValue(
            transformFloatToNumberFormatValues(
              Number(auctionWithData.repaired_damage_value),
            ),
          )
          setEstimatedDamageCost(
            transformFloatToNumberFormatValues(
              Number(auctionWithData.estimated_damage_cost),
            ),
          )
          setKvaFn?.(
            transformFloatToNumberFormatValues(
              Number(auctionWithData.estimated_damage_cost),
            ),
          )
          setNotes(auctionWithData.notes)
          setUrl(auctionWithData.url ? auctionWithData.url : '')
        }
      }
      setShowroom(vehicleDetails.showroom ?? false)
      setReserved(vehicleDetails.reserved ?? '')
      setCrash(vehicleDetails.crash ?? false)
      setExtraTyres(vehicleDetails.extra_tyres ?? null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleDetails?.auction, vehicleDetails?.bundle_auction])

  function validateRepairedDamageValue() {
    return (repairedDamageValue.floatValue ?? -1) >= 0
  }

  function validateEstimatedDamageCost() {
    return (estimatedDamageCost.floatValue ?? -1) >= 0
  }

  function validateUrl(url: string) {
    const regex = new RegExp('^(http(s):\\/\\/..[a-z]{2,}\\S*)$')
    return regex.test(url)
  }

  const handleSubmit = async () => {
    setSubmitted(true)
    setDisabled(true)

    if (validInitialPrice(initialPrice) && validStartDate(startDate)) {
      const auctionWithData = [vehicleDetails?.auction].find(
        (item) =>
          item &&
          (convertUtcTimeToGermanTime(new Date(item.start_date)) >
            convertUtcTimeToGermanTime(new Date()) ||
            item.start_date === null),
      )

      const damageInfo = {
        repaired_damage_value: repairedDamageValue.floatValue,
        estimated_damage_cost: estimatedDamageCost.floatValue,
        notes,
        url,
      }

      const vehicleRequest = () =>
        callBackendAPI(
          `${environment.B2B_BACKEND_BASE_URL}/api/internal/vehicles`,
          'PUT',
          {
            vin: params.vin,
            bundle_auction: bundleAuction,
            initial_price: initialPrice.floatValue,
            extra_tyres: extraTyres ?? false,
            ...damageInfo,
          },
        )

      const auctionRequest = () =>
        auctionWithData !== undefined
          ? callBackendAPI(
              `${environment.B2B_BACKEND_BASE_URL}/api/auctions`,
              'PUT',
              {
                _id: auctionWithData._id,
                vin: auctionWithData.vin,
                initial_price: initialPrice.floatValue,
                start_date: startDate ? `${startDate}T09:00:00Z` : null,
                ...damageInfo,
              },
            )
          : callBackendAPI(
              `${environment.B2B_BACKEND_BASE_URL}/api/auctions`,
              'POST',
              {
                auction: {
                  vin: vehicleDetails?.vin,
                  initial_price: initialPrice.floatValue,
                  start_date: startDate ? `${startDate}T09:00:00Z` : null,
                  ...damageInfo,
                },
              },
            )

      try {
        const vehicleResponse = await vehicleRequest()

        if (vehicleResponse.status !== 200) {
          console.error(`Failed to update vehicle's data`)
          setSubmitFailed(true)
          return
        }

        if (!bundleAuction) {
          const auctionResponse = await auctionRequest()

          if (
            auctionResponse!.status !== 200 &&
            auctionResponse!.status !== 201
          ) {
            console.error(`Failed to update auction's data`)
            setSubmitFailed(true)
            return
          }
        }

        setSubmitFailed(false)
        navigate(`/details/${params.vin}`)
        addMessage({
          text: t('sellerView.auctionSuccessfullySaved'),
          state: 'success',
        })
      } catch (error) {
        setSubmitFailed(true)
        console.error(error)
      }
    }
  }

  let setKvaFn: React.Dispatch<React.SetStateAction<NumberFormatValues>>
  return (
    <>
      <div className={tabsBarStyle.whiteBackground}>
        <PButtonPure
          className={styles.backButton}
          onClick={() => {
            navigate(`/details/${params.vin}`)
          }}
          icon="arrow-head-left"
        >
          {t('sellerView.backToTheDetailsPageButton')}
        </PButtonPure>

        <PHeading tag="h3" size="large" className={styles.detailsHeadline}>
          {vehicleDetails?.derivative}
        </PHeading>
        <PText className={styles.paddingBottom}>{vehicleDetails?.vin}</PText>
      </div>

      <div className={tabsBarStyle.centralizedContent}>
        <div className={styles.detailBlock}>
          <div className={styles.formContainer} style={{ width: 'inherit' }}>
            <div>
              <PButtonGroup>
                <PButton
                  variant={bundleAuction === false ? 'primary' : 'secondary'}
                  icon="car"
                  onClick={() => setBundleAuction(false)}
                >
                  {t('sellerView.singleAuction')}
                </PButton>
                <PButton
                  variant={bundleAuction === true ? 'primary' : 'secondary'}
                  icon="gift"
                  onClick={() => setBundleAuction(true)}
                >
                  {t('sellerView.bundleAuction')}
                </PButton>
              </PButtonGroup>

              <div className={styles.formContent}>
                <div>
                  <div className={styles.vehicleSwitches}>
                    <PSwitch
                      className={styles.vehicleSwitchStatuses}
                      hideLabel={false}
                      disabled={crash}
                      checked={showroom}
                      onUpdate={onUpdateShowroom}
                    >
                      {t('sellerView.showroom').toString()}
                    </PSwitch>

                    <PSwitch
                      className={styles.vehicleSwitchStatuses}
                      hideLabel={false}
                      disabled={crash}
                      checked={!!reserved}
                      onUpdate={onUpdateReserved}
                    >
                      {t('sellerView.reserved.label').toString()}
                    </PSwitch>
                    <PPopover
                      className={styles.reservedPopover}
                      aria={{ 'aria-label': 'Reserved Hint' }}
                    >
                      {t('sellerView.reserved.hint')}
                    </PPopover>
                    <PSwitch
                      className={styles.vehicleSwitchStatuses}
                      hideLabel={false}
                      disabled={showroom || !!reserved}
                      checked={crash}
                      onUpdate={onUpdateCrash}
                    >
                      {t('sellerView.crash').toString()}
                    </PSwitch>
                  </div>

                  <PTextFieldWrapper
                    label={`${t('sharedView.vehicleInitialPrice')} (${t('sharedView.net')})`}
                    unit="€"
                    unitPosition="suffix"
                    state={
                      (initialPrice.floatValue != null || submitted) &&
                      !validInitialPrice(initialPrice)
                        ? 'error'
                        : 'none'
                    }
                    message={t(
                      'sellerView.priceMustBeGreaterThanZero',
                    ).toString()}
                    className={styles.formInput}
                  >
                    <NumericFormat
                      name="initial-price"
                      required
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={0}
                      fixedDecimalScale
                      value={initialPrice.floatValue}
                      onValueChange={(e) => {
                        setInitialPrice(e)
                      }}
                    />
                  </PTextFieldWrapper>

                  {!bundleAuction && (
                    <PTextFieldWrapper
                      label={t('sharedView.auctionStartDate').toString()}
                      state={
                        ((startDate !== '' || submitted) &&
                          !validStartDate(startDate)) ||
                        !startDateIsNotOnWeekend(startDate)
                          ? 'error'
                          : 'none'
                      }
                      message={t(
                        'sellerView.dateMustBeInTheFutureAndNotOnAWeekend',
                      ).toString()}
                      className={styles.formInput}
                    >
                      <input
                        type="date"
                        name="start-date"
                        aria-label="start date"
                        value={startDate}
                        onChange={(e) =>
                          setStartDate(
                            e.target.value &&
                              new Date(e.target.value)
                                .toISOString()
                                .split('T')[0],
                          )
                        }
                      />
                    </PTextFieldWrapper>
                  )}

                  <PTextFieldWrapper
                    unit="€"
                    unitPosition="suffix"
                    state={
                      (repairedDamageValue.floatValue != null || submitted) &&
                      !validateRepairedDamageValue()
                        ? 'error'
                        : 'none'
                    }
                    message={`${t('sharedView.vehicleDamageValue')} ${t(
                      'sellerView.cannotBeNegative',
                    )}`}
                    className={styles.formInput}
                  >
                    <span slot="label">
                      {t('sharedView.vehicleDamageValue')}
                      <PPopover
                        className={styles.popover}
                        aria={{ 'aria-label': 'Schaden' }}
                      >
                        {t('sellerView.DamageInfo')}
                      </PPopover>
                    </span>
                    <NumericFormat
                      data-testid="repaired-damage-value"
                      required
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                      value={repairedDamageValue.floatValue}
                      onValueChange={(e) => {
                        setRepairedDamageValue(e)
                      }}
                    />
                  </PTextFieldWrapper>

                  <PTextFieldWrapper
                    unit="€"
                    unitPosition="suffix"
                    state={
                      (estimatedDamageCost.floatValue != null || submitted) &&
                      !validateEstimatedDamageCost()
                        ? 'error'
                        : 'none'
                    }
                    message={`KVA ${t('sellerView.cannotBeNegative')}`}
                    className={styles.formInput}
                  >
                    <span slot="label">
                      KVA
                      <PPopover
                        className={styles.popover}
                        aria={{ 'aria-label': 'KVA' }}
                      >
                        {t('sellerView.KVAInfo')}
                      </PPopover>
                    </span>
                    <NumericFormat
                      data-testid="estimated-damage-cost"
                      required
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                      value={estimatedDamageCost.floatValue}
                      onValueChange={(e) => {
                        setEstimatedDamageCost(e)
                        setKvaFn?.(e)
                      }}
                    />
                  </PTextFieldWrapper>

                  <PTextareaWrapper
                    label={t('sharedView.vehicleNotes').toString()}
                    hideLabel={false}
                    showCounter={false}
                    className={styles.textArea}
                  >
                    <textarea
                      name="notes"
                      maxLength={250}
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </PTextareaWrapper>

                  <PTextFieldWrapper
                    state={url !== '' && !validateUrl(url) ? 'error' : 'none'}
                    message={t('sellerView.invalidUrl').toString()}
                    className={styles.formInput}
                  >
                    <span slot="label">{t('sharedView.auctionUrl')}</span>
                    <input
                      data-testid="url"
                      type="url"
                      placeholder="https://appraisal.timas.app"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                    />
                  </PTextFieldWrapper>
                  <div className={styles.vehicleSwitches}>
                    <PSwitch
                      className={styles.vehicleSwitchStatuses}
                      hideLabel={false}
                      checked={extraTyres ?? false}
                      onUpdate={onUpdateExtraTyres}
                    >
                      {t('sharedView.extraTyres').toString()}
                    </PSwitch>
                  </div>
                </div>
                <div className={styles.calculator}>
                  <CalculatorBlock
                    msrp={vehicleDetails?.gross_price}
                    setSetKvaHook={(fn) => {
                      setKvaFn = fn
                    }}
                    confirmFn={(newKva, newInitialPrice) => {
                      setEstimatedDamageCost(newKva)
                      setInitialPrice(newInitialPrice)
                      const obj = document
                        .getElementsByName('initial-price')
                        .item(0)
                      obj?.scrollIntoView({ behavior: 'smooth' })
                    }}
                  />
                </div>
              </div>
            </div>

            <PButtonGroup className={styles.formButton}>
              <PButton
                variant="primary"
                aria-label="save"
                disabled={
                  initialPrice.floatValue == null ||
                  repairedDamageValue.floatValue == null ||
                  estimatedDamageCost.floatValue == null ||
                  !validateRepairedDamageValue() ||
                  !validateEstimatedDamageCost() ||
                  !validInitialPrice(initialPrice) ||
                  !validStartDate(startDate) ||
                  !startDateIsNotOnWeekend(startDate) ||
                  (url !== '' && !validateUrl(url)) ||
                  disabled
                }
                onClick={() => handleSubmit()}
              >
                {t('sellerView.saveButton')}
              </PButton>
              <PButton
                variant="tertiary"
                icon="close"
                onClick={() => {
                  navigate(`/details/${params.vin}`)
                }}
              >
                {t('sellerView.cancelButton')}
              </PButton>
            </PButtonGroup>
          </div>
          {submitFailed && (
            <PInlineNotification
              state="error"
              heading={t('sellerView.errorSendingData').toString()}
              description={t(
                'sellerView.errorSendingDataDescription',
              ).toString()}
              dismissButton={true}
              className={styles.notification}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default AuctionPage
