import React, { useCallback, useContext, useEffect, useState } from 'react'
import styles from '../IncomingTab/IncomingVehiclesContent.module.css'
import {
  PaginationUpdateEvent,
  PButton,
  PPagination,
  PPopover,
  PTable,
  PTableBody,
  PTableHead,
  PTableHeadCell,
  PTableHeadRow,
  PText,
} from '@porsche-design-system/components-react'
import SoldVehicleRow from './SoldVehicleRow'
import {
  AVAILABLE_DERIVATIVE_FILTERS,
  AVAILABLE_MODEL_FILTERS,
  NO_DERIVATIVE_SELECTED,
} from '../../Filters/FilterConstants'
import FilterRow from '../../Filters/FilterRow'
import loadEnvironment from '../../../loadEnvironment'
import useSoldVehicles from '../../../Hooks/useSoldVehicles'
import { callBackendAPI } from '../../../api'
import { UserInfo } from '../../../App'
import { useTranslation } from 'react-i18next'
import {
  readFromSessionStorage,
  useSessionStorage,
} from '../../../Hooks/filterHooks'

export default function SoldVehiclesContent(props: { pageSize: number }) {
  const environment = loadEnvironment()
  const { t } = useTranslation()
  const [pageIndex, setPageIndex] = useState(1)
  const [modelFilter, setModelFilter] = useState(
    readFromSessionStorage(
      'modelFilter',
      AVAILABLE_MODEL_FILTERS[0],
      (x: string) => AVAILABLE_MODEL_FILTERS.includes(x),
    ),
  )
  useSessionStorage(modelFilter, 'modelFilter')
  const [derivativeFilter, setDerivativeFilter] = useState(
    readFromSessionStorage(
      'derivativeFilter',
      NO_DERIVATIVE_SELECTED,
      (x: string) =>
        x.split(',').find((z) => AVAILABLE_DERIVATIVE_FILTERS.includes(z)) !==
        undefined,
    ).split(','),
  )
  useSessionStorage(derivativeFilter.toString(), 'derivativeFilter')
  const [downloadingCSV, setDownloadingCSV] = useState(false)
  const { isSeller } = useContext(UserInfo)

  const { vehicles, totalVehicles } = useSoldVehicles(
    modelFilter,
    derivativeFilter,
    pageIndex,
    props.pageSize,
  )

  const onUpdate = useCallback((e: CustomEvent<PaginationUpdateEvent>) => {
    setPageIndex(e.detail.page)
  }, [])

  //TODO: add filter in dependency
  useEffect(() => setPageIndex(1), [modelFilter, derivativeFilter])

  return (
    <>
      <PText className={styles.listSize} size="small" weight="semi-bold">
        {t('sharedView.vehicleCount', { count: totalVehicles })}
      </PText>

      <div className={styles.filterCountDownRow}>
        <FilterRow
          onChangeModel={setModelFilter}
          modelFilter={modelFilter}
          onChangeDerivative={setDerivativeFilter}
          derivativeFilter={derivativeFilter}
          onChangeArbitraryDate={null}
          arbitraryDateFilter={null}
        />

        {isSeller && (
          <PButton
            className={styles.exportButton}
            variant="primary"
            loading={downloadingCSV}
            onClick={() => {
              setDownloadingCSV(true)

              callBackendAPI(
                `${environment.B2B_BACKEND_BASE_URL}/api/not-invoiced-vehicles.csv`,
              )
                .then((response) => response.data)
                .then((csv) => {
                  const url = window.URL.createObjectURL(new Blob([csv]))
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', `not-invoiced-vehicles.csv`)

                  document.body.appendChild(link)

                  link.click()

                  link.parentNode!.removeChild(link)
                })
                .finally(() => {
                  setDownloadingCSV(false)
                })
            }}
          >
            {t('sellerView.exportButton')}
          </PButton>
        )}
      </div>

      {totalVehicles !== 0 && (
        <PTable caption="Some caption" className={styles.contentTable}>
          <PTableHead>
            <PTableHeadRow>
              <PTableHeadCell role="columnheader">
                <PText weight="semi-bold">
                  {t('sharedView.vehiclePictures')}
                </PText>
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                <PText weight="semi-bold">{t('sharedView.vehicleModel')}</PText>
                <PText weight="semi-bold">
                  {t('sharedView.vehicleIdentificator')}
                </PText>
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                <PText weight="semi-bold">
                  {t('sharedView.porscheCenter')}
                </PText>
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                <div className={styles.inlinePopoverContainer}>
                  <PText weight="semi-bold">
                    {t('sharedView.auctionWinningBid')}
                  </PText>
                  <PPopover
                    className={styles.popover}
                    aria={{
                      'aria-label':
                        'Auction winning bid additional information',
                    }}
                  >
                    {t('sharedView.vehicleNettoPrice')}
                  </PPopover>
                </div>
                <PText weight="semi-bold">
                  {t('sharedView.auctionEndDate')}
                </PText>
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                <PText weight="semi-bold">Status</PText>
              </PTableHeadCell>
            </PTableHeadRow>
          </PTableHead>
          <PTableBody>
            {vehicles?.map(
              (item, i) =>
                item.auction && <SoldVehicleRow key={i} vehicle={item} />,
            )}
          </PTableBody>
        </PTable>
      )}
      {vehicles?.length > 0 && (
        <PPagination
          className={styles.pagination}
          totalItemsCount={totalVehicles}
          itemsPerPage={props.pageSize}
          activePage={pageIndex}
          onUpdate={onUpdate}
        />
      )}
    </>
  )
}
