import React, { Dispatch, SetStateAction, useCallback, useContext } from 'react'
import { AVAILABLE_MODEL_FILTERS } from './FilterConstants'
import SelectModel from './SelectModel'
import {
  PCheckboxWrapper,
  PTagDismissible,
} from '@porsche-design-system/components-react'
import styles from './FilterRow.module.css'
import SelectArbitraryDate from './SelectArbitraryDate'
import SelectDerivative from './SelectDerivative'
import { useTranslation } from 'react-i18next'
import { UserInfo } from '../../App'

function getDate(dateType?: string) {
  return dateType === undefined || dateType === null
    ? '—'
    : new Date(Date.parse('' + dateType)).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
}

const FilterRow = (props: {
  onChangeModel: Dispatch<SetStateAction<string>>
  modelFilter: string
  onChangeDerivative: Dispatch<SetStateAction<string[]>>
  derivativeFilter: string[]
  onChangeArbitraryDate: Dispatch<SetStateAction<string>> | null
  arbitraryDateFilter: string | null
  onFavCheckboxChange?: Dispatch<SetStateAction<boolean>>
  favFilterDefaultValue?: boolean
}) => {
  const { t } = useTranslation()
  const { isDealer } = useContext(UserInfo)

  const forceDeselectModelFilter = useCallback(
    () => props.onChangeModel(AVAILABLE_MODEL_FILTERS[0]),
    [props],
  )

  const forceDeselectArbitraryDateFilter = useCallback(() => {
    if (props.onChangeArbitraryDate != null) {
      props.onChangeArbitraryDate('')
    }
  }, [props])

  const displayPTagDismissible = (
    tagText: string,
    deselectFilter: () => void,
  ) => {
    return (
      <PTagDismissible
        color="background-surface"
        onClick={() => deselectFilter()}
        className={styles.tags}
      >
        {tagText}
      </PTagDismissible>
    )
  }
  const modelFilterSelected = props.modelFilter !== AVAILABLE_MODEL_FILTERS[0]

  return (
    <div className={styles.filterRowWrapper}>
      <div className={styles.displayInARow}>
        <SelectModel
          onChange={props.onChangeModel}
          modelFilter={props.modelFilter}
        />
        <SelectDerivative
          onChange={props.onChangeDerivative}
          selectedModel={props.modelFilter}
          derivativeFilter={props.derivativeFilter}
        />
        {props.arbitraryDateFilter != null &&
          props.onChangeArbitraryDate != null && (
            <SelectArbitraryDate
              onChange={props.onChangeArbitraryDate}
              dateFilter={props.arbitraryDateFilter}
            />
          )}
        {isDealer && props.onFavCheckboxChange != null && (
          <PCheckboxWrapper
            className={styles.favouriteFilter}
            label={t('dealerView.favoriteFilter')}
            hideLabel={false}
            data-testid="favourite-checkbox"
          >
            <input
              type="checkbox"
              name="favouriteFilterCheckbox"
              aria-label="favouriteFilterCheckbox"
              checked={props.favFilterDefaultValue}
              onChange={(event) => {
                props.onFavCheckboxChange?.(event.target.checked)
              }}
            />
          </PCheckboxWrapper>
        )}
      </div>
      <div className={styles.displayInARow}>
        {modelFilterSelected &&
          displayPTagDismissible(props.modelFilter, forceDeselectModelFilter)}
        {props.arbitraryDateFilter != null &&
          props.arbitraryDateFilter !== '' &&
          displayPTagDismissible(
            getDate(props.arbitraryDateFilter),
            forceDeselectArbitraryDateFilter,
          )}
      </div>
    </div>
  )
}

export default FilterRow
