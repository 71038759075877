import styles from './DetailsPage.module.css'
import React, { useState } from 'react'
import {
  PButton,
  PButtonGroup,
  PInlineNotification,
  PModal,
  PText,
  useToastManager,
} from '@porsche-design-system/components-react'
import { t } from 'i18next'
import { callBackendAPI } from '../../../api'
import loadEnvironment from '../../../loadEnvironment'
import { useNavigate } from 'react-router-dom'
import PackageVehicleImages from './PackageDetailsPage/PackageVehicleImages'
import { Vehicle } from '../../../Models/Vehicle'

export const DeletePackageModal = (props: {
  vin: string
  packageNumber: number
  open: boolean
  closingHandler: () => void
  vehicles: Vehicle[]
}) => {
  const environment = loadEnvironment()

  const navigate = useNavigate()
  const { addMessage } = useToastManager()

  const [deleteFailed, setDeleteFailed] = useState(false)

  const deletePackage = async () => {
    const deletePackageRequest = () =>
      callBackendAPI(
        `${environment.B2B_BACKEND_BASE_URL}/api/auctions?vin=${props.vin}`,
        'DELETE',
        undefined,
      )
    try {
      const deleteResponse = await deletePackageRequest()
      if (deleteResponse.status !== 200) {
        console.error(`Failed to delete package auction`)
        setDeleteFailed(true)
        return
      }

      setDeleteFailed(false)
      navigate(`/returned`)
      addMessage({
        text: t('sellerView.packageSuccessfullyDeleted'),
        state: 'success',
      })
    } catch (error) {
      console.error(error)
      setDeleteFailed(true)
    }
  }

  return (
    <PModal
      open={props.open}
      heading={t('sellerView.deletePackageButton')}
      data-testid="delete-package-modal"
      onDismiss={() => props.closingHandler()}
    >
      <PackageVehicleImages vehicles={props.vehicles} />
      <PText
        className={styles.deletePackageConfirmation}
        data-testid="delete-package-confirmation-message"
      >
        {t('sellerView.deletePackageConfirmationMessage', {
          packageId: props.packageNumber, //vehicleWithDetails?.auction?.package_number,
        })}
      </PText>
      <PButtonGroup
        className="footer"
        data-testid="delete-package-cancel-button"
      >
        <PButton onClick={() => deletePackage()}>
          {t('sellerView.deleteButton')}
        </PButton>
        <PButton
          type="button"
          variant="tertiary"
          data-testid="cancel-button"
          onClick={() => props.closingHandler()}
        >
          {t('sellerView.cancelButton')}
        </PButton>
      </PButtonGroup>
      {deleteFailed && (
        <PInlineNotification
          state="error"
          heading={t('sellerView.errorDeletingPackage').toString()}
          description={t(
            'sellerView.errorDeletingPackageDescription',
          ).toString()}
          dismissButton={true}
          className={styles.notification}
        />
      )}
    </PModal>
  )
}
