import React, { useCallback, useState } from 'react'
import {
  PButton,
  PText,
  PFlyout,
  PHeading,
  PButtonGroup,
  PSegmentedControl,
  PSegmentedControlItem,
  PTextareaWrapper,
  SegmentedControlUpdateEventDetail,
} from '@porsche-design-system/components-react'
import { t } from 'i18next'
import styles from './FeedbackFlyout.module.css'

export const FeedbackFlyout = (): JSX.Element => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false)
  const onOpen = useCallback(() => {
    setIsFlyoutOpen(true)
  }, [])
  const onDismiss = useCallback(() => {
    setIsFlyoutOpen(false)
  }, [])

  const [currentMark, setCurrentMark] = useState<number>(-1)
  const onMarkUpdate = useCallback(
    (event: CustomEvent<SegmentedControlUpdateEventDetail>) => {
      setCurrentMark(event.detail.value as number)
    },
    [],
  )

  return (
    <>
      <PButton
        type="button"
        aria={{ 'aria-haspopup': 'dialog' }}
        onClick={onOpen}
        className={styles.floating}
      >
        {t('sharedView.feedback')}
      </PButton>
      <PFlyout
        open={isFlyoutOpen}
        onDismiss={onDismiss}
        aria={{ 'aria-label': 'Feedback flyout' }}
      >
        <PHeading slot="header" size="large" tag="h2">
          {t('sharedView.feedback')}
        </PHeading>

        <PText weight="bold">{t('sharedView.howSatisfiedAreYou')}</PText>
        <div className={styles.alignInRow}>
          <PText>{t('sharedView.veryDissatisfied')}</PText>
          <PSegmentedControl
            aria-label="Choose a satisfaction mark"
            className={`${styles.alignInRow} ${styles.markSelector}`}
            value={currentMark}
            onUpdate={onMarkUpdate}
          >
            <PSegmentedControlItem value="1">1</PSegmentedControlItem>
            <PSegmentedControlItem value="2">2</PSegmentedControlItem>
            <PSegmentedControlItem value="3">3</PSegmentedControlItem>
            <PSegmentedControlItem value="4">4</PSegmentedControlItem>
            <PSegmentedControlItem value="5">5</PSegmentedControlItem>
          </PSegmentedControl>
          <PText>{t('sharedView.verySatisfied')}</PText>
        </div>

        <PText weight="bold" className={styles.whatCanWeDoToImproveText}>
          {t('sharedView.whatCanWeDoToImprove')}
        </PText>
        <PTextareaWrapper
          label={'what-can-we-do-to-improve-textarea'}
          hideLabel={true}
          showCounter={false}
        >
          <textarea maxLength={500} name="what-can-we-do-to-improve-textarea" />
        </PTextareaWrapper>

        <PButtonGroup slot="footer">
          <PButton type="button" disabled={currentMark === -1}>
            {t('sharedView.submitFeedback')}
          </PButton>
          <PButton type="button" variant="secondary" onClick={onDismiss}>
            {t('sellerView.cancelButton')}
          </PButton>
        </PButtonGroup>
      </PFlyout>
    </>
  )
}
