import { Vehicle } from '../../../../Models/Vehicle'
import { PHeading, PTag, PText } from '@porsche-design-system/components-react'
import { useTranslation } from 'react-i18next'
import { createDetailsRow } from '../SummaryBlock'
import {
  determineVehicleStatus,
  isActive,
  isFutureAuction,
  VEHICLE_STATUS,
} from '../../../../helpers/vehicleStatus'
import React, { useContext } from 'react'
import { UserInfo } from '../../../../App'
import { Auction } from '../../../../Models/Auction'
import { getDate } from '../../LiveSaleTab/LiveSaleSingleAuctions/LiveSaleVehicleRow'
import styles from '../DetailsPage.module.css'
import { priceFormatting } from '../../../../helpers/helperFunctions'
import { Bid } from '../../../../Models/Bid'
import { calculateAuctionEnd } from '../../../AuctionCountdown'
import PackageVehicleImages from '../../DetailsPage/PackageDetailsPage/PackageVehicleImages'

export const determinePackageStatus = (packageAuction?: Auction) => {
  if (
    packageAuction?.package_number === undefined ||
    packageAuction?.package_number === null
  )
    return '–'
  if (isFutureAuction(packageAuction)) {
    return VEHICLE_STATUS.ready
  } else if (isActive(packageAuction)) {
    return VEHICLE_STATUS.sale
  } else {
    return '–'
  }
}
export const PackageSummaryBlock = (props: {
  vehicleWithDetails: Vehicle | null
  currentHighestBid: number
  maxBidId: string | undefined
  userMaxBid: Bid | undefined
  totalBids: number | undefined
}) => {
  const { t } = useTranslation()
  const { isSeller, isDealer } = useContext(UserInfo)

  const { vehicleWithDetails } = props
  const auction = vehicleWithDetails?.auction

  const packageStatus = determinePackageStatus(auction)

  const status = determineVehicleStatus(
    props.vehicleWithDetails?.primary_status,
    props.vehicleWithDetails?.secondary_status,
    Boolean(props.vehicleWithDetails?.blocked_for_sales),
    props.vehicleWithDetails?.auction,
    isSeller,
    isDealer,
    props.vehicleWithDetails?.has_a_complete_auction,
  )

  return (
    <>
      <div className={styles.displayInRow}>
        <PHeading tag="h3" size="large" className={styles.goToBidButton}>
          {t('sharedView.packageSummaryBlockTitle')}
        </PHeading>
        <PText>{`(${auction?.package_number})`}</PText>
      </div>
      <div className={styles.summaryContent}>
        <div className={styles.displayPackageVehicleImagesDetails}>
          <div>
            <PackageVehicleImages vehicles={auction?.vehicles} />
          </div>
        </div>
        <div className={styles.summaryDetails}>
          {createDetailsRow(
            t('sharedView.packageSummaryPackageID'),
            auction?.package_number,
          )}
          {createDetailsRow(
            t('sharedView.packageSummaryVehicleCount'),
            auction?.vins?.length,
          )}
          {createDetailsRow(
            t('sellerView.packageInitialPrice'),
            priceFormatting(Number(auction?.initial_price)) +
              ` (${t('sharedView.net')})`,
          )}
          {isDealer &&
            createDetailsRow(
              t('dealerView.currentHighestBid'),
              props.currentHighestBid
                ? priceFormatting(Number(props.currentHighestBid)) +
                    ` (${t('sharedView.net')})`
                : '-',
            )}
          {isDealer &&
            createDetailsRow(
              t('dealerView.myMaximumBid'),
              props.userMaxBid
                ? priceFormatting(Number(props.userMaxBid?.bid_amount)) +
                    ` (${t('sharedView.net')})`
                : '-',
            )}

          {status === 'dealerSale' && // TODO: refactor and display to sellers too
            isDealer &&
            createDetailsRow(
              t('dealerView.myStatus').toString(),
              props.userMaxBid ? (
                Number(props.userMaxBid.bid_amount) >
                  Number(vehicleWithDetails?.auction?.current_bid) ||
                (Number(props.userMaxBid.bid_amount) ===
                  Number(vehicleWithDetails?.auction?.current_bid) &&
                  props.userMaxBid._id === props.maxBidId) ? (
                  <PTag color="notification-success-soft">
                    {t('dealerView.highestBid')}
                  </PTag>
                ) : (
                  <PTag color="notification-error-soft">
                    {t('dealerView.outbid')}
                  </PTag>
                )
              ) : (
                <PTag color="notification-info-soft">
                  {t('dealerView.noBid')}
                </PTag>
              ),
            )}

          {createDetailsRow(t('sharedView.bidsAmount'), props.totalBids)}

          {isSeller &&
            createDetailsRow(
              'Status',
              <PTag
                color={`notification-${
                  packageStatus === VEHICLE_STATUS.previously_auctioned
                    ? 'warning-soft'
                    : 'info-soft'
                }`}
              >
                {t(packageStatus)}
              </PTag>,
            )}

          {packageStatus !== '–' &&
            createDetailsRow(
              t('sharedView.auctionEndDate').toString(),
              props.vehicleWithDetails?.auction
                ? t(calculateAuctionEnd(props.vehicleWithDetails?.auction))
                : '–',
            )}

          {isDealer &&
            isFutureAuction(auction) &&
            createDetailsRow(
              'Status',
              <PTag color={`notification-info-soft`}>{'Preview'}</PTag>,
            )}
          {packageStatus === VEHICLE_STATUS.ready &&
            createDetailsRow(
              t('sharedView.auctionStartDate'),
              getDate(auction?.start_date),
            )}
          {createDetailsRow(t('sharedView.vehicleNotes'), auction?.notes)}
        </div>
      </div>
    </>
  )
}
