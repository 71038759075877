import {
  PButton,
  PButtonGroup,
  PDivider,
  PHeading,
  PLinkPure,
  PSelectWrapper,
  PText,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react'
import { useTranslation } from 'react-i18next'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import React, { useState } from 'react'
import styles from '../DetailsPage/DetailsPage.module.css'

const transformFloatToNumberFormatValues = (floatNumber: number) => {
  return {
    formattedValue: new Intl.NumberFormat('de-DE').format(floatNumber),
    floatValue: floatNumber,
  } as NumberFormatValues
}

export const CalculatorBlock = (props: {
  msrp?: number
  setSetKvaHook?: (
    setFn: React.Dispatch<React.SetStateAction<NumberFormatValues>>,
  ) => void
  confirmFn: (
    newKva: NumberFormatValues,
    newInitialPrice: NumberFormatValues,
  ) => void
}) => {
  const { t } = useTranslation()

  const msrp = transformFloatToNumberFormatValues(props.msrp || 0)
  const [priceFinder, setPriceFinder] = useState({} as NumberFormatValues)
  const [priceFinderNet, setPriceFinderNet] = useState({} as NumberFormatValues)
  const [selectedDiscount, setSelectedDiscount] = useState('10')
  const [kva, setKva] = useState({} as NumberFormatValues)
  const [initialPrice, setInitialPrice] = useState({} as NumberFormatValues)
  props.setSetKvaHook?.(setKva)

  function updateInitialPrice(
    priceFinder: NumberFormatValues,
    selectedDiscount: string,
    kva: NumberFormatValues,
  ) {
    const priceFinderNet =
      (priceFinder.floatValue && priceFinder.floatValue / 1.19) || 0
    const selectedDiscountPercent = parseFloat(selectedDiscount)
    const newInitialPrice = transformFloatToNumberFormatValues(
      priceFinderNet * (1 - selectedDiscountPercent / 100) -
        (kva.floatValue || 0),
    )
    setInitialPrice(newInitialPrice)
    return newInitialPrice
  }
  function validateEstimatedDamageCost() {
    return (kva.floatValue ?? -1) >= 0
  }
  const percentage = (priceFinder.floatValue || 0) / (msrp.floatValue || 0)
  return (
    <div>
      <PHeading tag="h3" size="large" className={styles.calculatorHeader}>
        {t('sellerView.calculator.title')}
      </PHeading>
      <PTextFieldWrapper
        unit="€"
        unitPosition="suffix"
        state="none"
        label={t('sellerView.calculator.msrp')}
        className={styles.formInput}
      >
        <NumericFormat
          readOnly={true}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale
          value={msrp.floatValue}
        />
      </PTextFieldWrapper>
      <div>
        <PText>
          {t('sellerView.calculator.priceFinder') +
            ' (' +
            t('sharedView.gross') +
            ')'}
          <PLinkPure
            icon="external"
            size="small"
            href={'https://finder.porsche.com/'}
            target="_blank"
            rel="noreferrer"
            className={styles.priceFinderLinkIcon}
          ></PLinkPure>
        </PText>
        <PTextFieldWrapper
          unit="€"
          unitPosition="suffix"
          state="none"
          className={`${styles.formInput} ${styles.indicatorWrapper}`}
          hideLabel={true}
          label={
            t('sellerView.calculator.priceFinder') +
            ' (' +
            t('sharedView.gross') +
            ')'
          }
        >
          {(percentage && percentage < 3.5 && percentage > 0.45 && (
            <div
              className={
                styles.indicator +
                ' ' +
                (percentage > 1 ? styles.green : styles.red)
              }
            >
              {new Intl.NumberFormat('de-DE', {
                style: 'percent',
                maximumFractionDigits: 2,
                signDisplay: 'always',
              }).format(percentage - 1)}
            </div>
          )) ||
            ''}

          <NumericFormat
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            value={priceFinder.floatValue}
            onValueChange={(e) => {
              setPriceFinder(e)
              setPriceFinderNet(
                transformFloatToNumberFormatValues(
                  (e.floatValue && e.floatValue / 1.19) || 0,
                ),
              )
              updateInitialPrice(e, selectedDiscount, kva)
            }}
          />
        </PTextFieldWrapper>
      </div>
      <PTextFieldWrapper
        unit="€"
        unitPosition="suffix"
        state="none"
        label={
          t('sellerView.calculator.priceFinder') +
          ' (' +
          t('sharedView.net') +
          ')'
        }
        className={styles.formInput}
      >
        <NumericFormat
          readOnly={true}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale
          value={priceFinderNet.floatValue}
        />
      </PTextFieldWrapper>
      <PSelectWrapper
        label={t('sellerView.calculator.discount')}
        state="none"
        className={styles.formInput}
      >
        <select
          value={selectedDiscount}
          onChange={(e) => {
            setSelectedDiscount(e.target.value)
            updateInitialPrice(priceFinder, e.target.value, kva)
          }}
        >
          {[
            { label: '5%', value: '5' },
            { label: '7,5%', value: '7.5' },
            { label: '10%', value: '10' },
            { label: '12,5%', value: '12.5' },
            { label: '15%', value: '15' },
          ].map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </PSelectWrapper>
      <PTextFieldWrapper
        unit="€"
        unitPosition="suffix"
        state={
          kva.floatValue != null && !validateEstimatedDamageCost()
            ? 'error'
            : 'none'
        }
        message={`KVA ${t('sellerView.cannotBeNegative')}`}
        label={t('sellerView.calculator.estimatedDamage')}
        className={styles.formInput}
      >
        <NumericFormat
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale
          value={kva.floatValue}
          onValueChange={(e) => {
            setKva(e)
            updateInitialPrice(priceFinder, selectedDiscount, e)
          }}
        />
      </PTextFieldWrapper>
      <PDivider className={styles.calculatorDivider} />
      <PTextFieldWrapper
        unit="€"
        unitPosition="suffix"
        state="none"
        label={t('sharedView.vehicleInitialPrice') + ':'}
        className={styles.formInput + ' ' + styles.boldInput}
      >
        <NumericFormat
          readOnly={true}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale
          value={initialPrice.floatValue}
        />
      </PTextFieldWrapper>
      <PButtonGroup className={'footer'}>
        <PButton
          type="button"
          icon="compare"
          disabled={
            !initialPrice.floatValue ||
            initialPrice.floatValue <= 0 ||
            (!!kva.floatValue && !validateEstimatedDamageCost())
          }
          onClick={() => {
            props.confirmFn(
              kva,
              transformFloatToNumberFormatValues(
                Math.round((initialPrice.floatValue ?? 0) / 100) * 100,
              ),
            )
          }}
        >
          {t('sellerView.calculator.confirm')}
        </PButton>
      </PButtonGroup>
    </div>
  )
}
