import {
  PButtonPure,
  PIcon,
  PPopover,
  PTableCell,
  PTableRow,
  PText,
} from '@porsche-design-system/components-react'
import styles from '../../IncomingTab/IncomingVehiclesContent.module.css'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Vehicle } from '../../../../Models/Vehicle'
import { useTranslation } from 'react-i18next'
import {
  kilometerFormatting,
  priceFormatting,
} from '../../../../helpers/helperFunctions'

export function getDate(dateType?: string) {
  return dateType === undefined || dateType === null
    ? '—'
    : new Date(Date.parse('' + dateType)).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
}

export function getStartingDays(item: Vehicle) {
  return item.auction?.start_date === undefined ||
    item.auction?.start_date === null
    ? ''
    : (
        (Date.parse(item.auction.start_date) - Date.now()) /
        (1000 * 60 * 60 * 24)
      ).toFixed()
}

const ReadyForSaleVehicleRow = (props: { vehicle: Vehicle }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const navigateToDetails = () => {
    navigate(`/details/${props.vehicle.vin}`)
  }

  return (
    <PTableRow role="row">
      <PTableCell>
        <PButtonPure
          icon="none"
          onClick={navigateToDetails}
          aria-label="open vehicle details"
        >
          <img
            className={styles.image}
            src={props.vehicle.preview || '/Car-Preview.png'}
            alt="car preview"
          />
        </PButtonPure>
      </PTableCell>
      <PTableCell>
        {props.vehicle.derivative ? (
          <PButtonPure weight="bold" icon="none" onClick={navigateToDetails}>
            <u>{props.vehicle.derivative}</u>
            {props.vehicle.extra_tyres ? (
              <PIcon name="snowflake" size="small"></PIcon>
            ) : (
              <></>
            )}
          </PButtonPure>
        ) : (
          <PText>
            {'–'}
            {props.vehicle.extra_tyres ? (
              <PIcon name="snowflake" size="small"></PIcon>
            ) : (
              <></>
            )}
          </PText>
        )}
        <PText>{props.vehicle.vin}</PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {props.vehicle.exterior_color?.name}
          <PPopover
            className={styles.popover}
            direction="top"
            description={props.vehicle.exterior_color?.description}
          />
        </PText>
        <PText>
          {props.vehicle.interior_color?.name}
          <PPopover
            className={styles.popover}
            direction="top"
            description={props.vehicle.interior_color?.description}
          />
        </PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {getDate(props.vehicle.date_of_first_registration) || '—'}
        </PText>
        <PText>
          {(props.vehicle.mileage &&
            kilometerFormatting(props.vehicle.mileage)) ||
            '—'}
        </PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {priceFormatting(Number(props.vehicle.auction?.initial_price))}
        </PText>
        <PText>{priceFormatting(Number(props.vehicle.gross_price))}</PText>
      </PTableCell>
      <PTableCell>
        <div>
          <PText>{getDate(props.vehicle.auction?.start_date)}</PText>
          {getDate(props.vehicle.auction?.start_date) !== '—' && (
            <PText>
              {getStartingDays(props.vehicle) === '0'
                ? `(${t('sharedView.auctionStartToday')})`
                : getStartingDays(props.vehicle) === '1'
                  ? `(${t('sharedView.auctionStartTomorrow')})`
                  : `(${t('sharedView.auctionStartDatePrefix')}${getStartingDays(
                      props.vehicle,
                    )} ${t('sharedView.auctionStartDateSuffix')})`}
            </PText>
          )}
        </div>
      </PTableCell>
      <PTableCell>
        {props.vehicle.showroom ? (
          <PIcon name="home" size="small"></PIcon>
        ) : (
          <></>
        )}
        {props.vehicle.reserved ? (
          <PIcon name="user" size="small"></PIcon>
        ) : (
          <></>
        )}
        {props.vehicle.crash ? (
          <PIcon name="wrenches" size="small"></PIcon>
        ) : (
          <></>
        )}
      </PTableCell>
    </PTableRow>
  )
}

export default ReadyForSaleVehicleRow
