import React, { useEffect, useState } from 'react'
import { PTag } from '@porsche-design-system/components-react'
import { CountryRecords } from './CountryRecords'

export const CountryTag = (props: {
  countryCode: string | undefined
  className?: string
}) => {
  const { countryCode } = props
  const [isHidden, setIsHidden] = useState<boolean>(false)
  const [tagText, setTagText] = useState<string>('')

  useEffect(() => {
    if (!countryCode) {
      setTagText('Country Code is missing')
      setIsHidden(false)
      return
    }

    const countryRecord = CountryRecords[countryCode]
    if (!countryRecord) {
      setTagText('Country Code is unknown')
      setIsHidden(false)
      return
    }

    const tagText = `${countryCode} - ${countryRecord.countryName}`
    setTagText(countryRecord.isRightHandDrive ? `${tagText} (RHD)` : tagText)

    setIsHidden(countryCode === 'C00')
  }, [countryCode])

  return isHidden || !tagText ? (
    // Note: In PDS 3.12 hidden={isHidden} is not working hence we are using this workaround
    // hidden={isHidden} works with PDS 3.21
    // However, when tested with 3.21, our app breaks at lots of place hence we cannot upgrade
    <></>
  ) : (
    <PTag
      className={props.className}
      icon="exclamation"
      color="notification-warning-soft"
    >
      {tagText}
    </PTag>
  )
}
